import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    isLoadingLogin: false, // Esta cargando login y esperando respuesta
    loadGame: false, // Es un flag que indica acceso al juego y actualizacion de rutas permitidas al momento del logueo
    userLogin: null, // Esta autenticado y almacena datos del token
    failed: null, // Indica que hubo un inconveniente en la autenticacion por ejemplo usuario y/o pass no validos
    nameUser: '',
    email: '',
  },
  reducers: {
    startLogin: (state) => {
      state.isLoadingLogin = true;
      state.failed = false;
    },
    authentication: (state, action) => {
      state.isLoadingLogin = false;
      state.failed = null;
      state.userLogin = action.payload.userLogin;
      state.nameUser = action.payload.name;
      state.email = action.payload.email;
    },
    setGame: (state) => {
      state.loadGame = true;
    },
    autologin: (state, action) => {
      state.loadGame = true;
      state.isLoadingLogin = false;
      state.failed = null;
      state.userLogin = action.payload.userLogin;
      state.email = action.payload.email;
    },
    failed: (state, action) => {
      state.isLoadingLogin = false;
      state.failed = action.payload.message;
    },
  },
});

// Action creators are generated for each case reducer function
export const { startLogin, authentication, setGame, autologin, failed } = loginSlice.actions;
