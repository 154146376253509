import React, { useEffect, useState } from 'react';

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { GeneralCarrousel, StylesCardCarrousel } from './styles';

import CartBlue from '../../assets/img/CartBlue.svg';

import StarBlue from '../../assets/img/StarBlue.svg';
import QuestionMsg from '../../assets/img/message-question.svg';
import { useScreenViewPort } from '../../hooks/useScreenViewPort';
import { ViewPort } from '../../constants/device';
import { useTranslation } from 'react-i18next';
import { Button } from '../Buttons';
import { LinkButton } from '../Links';

export const CarrouselNFT = ({ handlePlay }) => {
  const { t } = useTranslation();
  const [slideSelected, setSlideSelected] = useState(1);
  const [slidesView, setSlidesView] = useState(3);
  let { screenViewPort } = useScreenViewPort();
  useEffect(() => {
    if (screenViewPort) {
      let number = 0;
      switch (screenViewPort) {
        case ViewPort.DESKTOP:
          number = 3;
          break;
        case ViewPort.TABLET:
          number = 2;
          break;
        case ViewPort.MOBILE:
          number = 1;
          break;
        default:
          break;
      }
      setSlidesView(number);
    }
  }, [screenViewPort]);

  return (
    <GeneralCarrousel>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={32}
        slidesPerView={slidesView}
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        /*  onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log('slide change')} */
        style={{ height: '100%', padding: '0 40px' }}
      >
        <SwiperSlide>
          <StylesCardCarrousel>
            <div className="card-main row">
              <div className="section-header">
                <p className="title-card">{t('website.gameAll.player')}</p>
                <p className="description-card">
                  {t('website.gameAll.descriptionPlayer')}
                  <b> {t('website.gameAll.questionPlayer')}</b>
                </p>
              </div>
              <div className="links">
                <Button text={t('playNow')} bgColor="var(--primary-color)" onPress={() => handlePlay()} />
                <LinkButton
                  text={t('website.cardPlay.buy')}
                  color="var(--primary-color-medium)"
                  hoverColor="var(--primary-color)"
                  fontSize="var(--fs-normal)"
                  Icon={CartBlue}
                />
              </div>
            </div>
          </StylesCardCarrousel>
        </SwiperSlide>
        <SwiperSlide>
          <StylesCardCarrousel>
            <div className="card-main row">
              <div className="section-header">
                <p className="title-card">{t('website.gameAll.legend')}</p>
                <p className="description-card">{t('website.gameAll.descriptionLegend')}</p>
              </div>
              <div className="links">
                <Button text={t('letsGo')} bgColor="var(--primary-color)" />
                <LinkButton
                  text={t('website.gameAll.linkLegend')}
                  color="var(--primary-color-medium)"
                  hoverColor="var(--primary-color)"
                  fontSize="var(--fs-normal)"
                  Icon={QuestionMsg}
                />
              </div>
            </div>
          </StylesCardCarrousel>
        </SwiperSlide>
        <SwiperSlide>
          <StylesCardCarrousel>
            <div className="card-main row">
              <div className="section-header">
                <p className="title-card">{t('website.gameAll.boss')}</p>
                <p className="description-card">{t('website.gameAll.descriptionBoss')}</p>
              </div>
              <div className="links">
                <Button text={t('buyNFT')} bgColor="var(--primary-color)" />
                <LinkButton
                  text={t('website.gameAll.financial')}
                  color="var(--primary-color-medium)"
                  hoverColor="var(--primary-color)"
                  fontSize="var(--fs-normal)"
                  Icon={StarBlue}
                />
              </div>
            </div>
          </StylesCardCarrousel>
        </SwiperSlide>
      </Swiper>
    </GeneralCarrousel>
  );
};
