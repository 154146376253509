import styled from 'styled-components';

export const SectionSelect = styled.section`
  min-width: 120px;
  & .ui.active.selection.dropdown,
  & .ui.selection.dropdown {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 14px;
  }

  & .ui.selection.visible.dropdown > .text:not(.default) {
    color: var(--primary-color-medium);
  }

  & .ui.selection.dropdown .menu > .item {
    font-size: 14px;
  }
`;
