import React, { useEffect, useState } from 'react';

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { GeneralCarrousel, StylesCardCarrousel } from './styles';
import Hiena from '../../assets/img/Legends/Hiena Llorona - Legend 3.png';
import Mantiz from '../../assets/img/Legends/Insecto Hoja-  Legend 2.png';
import Dolphin from '../../assets/img/Legends/Delfin - Legend 1.png';
import Toro from '../../assets/img/Legends/Toro.png';
import LegendHidden from '../../assets/img/Legends/legendHidden.svg';

import { Button } from '../Buttons';
import { useScreenViewPort } from '../../hooks/useScreenViewPort';
import { ViewPort } from '../../constants/device';
import { useTranslation } from 'react-i18next';
import { isAfter } from 'date-fns';

let data = [
  {
    id: 1,
    img: Toro,
    rarelyNFT: 'F',
    name: 'Rolling Calf Genesis',
    price: '0.45/DNAU',
  },
  {
    id: 3,
    img: LegendHidden,
    rarelyNFT: '',
    name: '',
    price: '0.50/DNAU',
    isHidden: true,
  },
  {
    id: 4,
    img: LegendHidden,
    rarelyNFT: '',
    name: '',
    price: '0.75/DNAU',
    isHidden: true,
  },

  {
    id: 6,
    img: LegendHidden,
    rarelyNFT: '',
    name: '',
    price: '0.57/DNAU',
    isHidden: true,
  },
  {
    id: 7,
    img: LegendHidden,
    rarelyNFT: '',
    name: '',
    price: '0.50/DNAU',
    isHidden: true,
  },
];
const dateStart = new Date(2022, 10, 26, 18, 0); //26 Noviembre
export const Carrousel = () => {
  const { t } = useTranslation();
  const [slideSelected, setSlideSelected] = useState(1);
  const [slidesView, setSlidesView] = useState(3);
  const [legends, setLegends] = useState(data);
  let { screenViewPort } = useScreenViewPort();
  useEffect(() => {
    const resultAfter = isAfter(new Date(), dateStart);
    if (resultAfter) {
      setLegends([
        {
          id: 1,
          img: Toro,
          rarelyNFT: 'F',
          name: 'Rolling Calf Genesis',
          price: '0.45/DNAU',
          isHidden: false,
        },
        {
          id: 3,
          img: Hiena,
          rarelyNFT: 'C',
          name: 'Hiena',
          price: '0.50/DNAU',
          isHidden: false,
        },
        /*  {
          id: 4,
          img: Mantiz,
          rarelyNFT: 'B',
          name: '',
          price: '0.75/DNAU',
          isHidden: false,
        }, */

        {
          id: 6,
          img: Dolphin,
          rarelyNFT: 'D',
          name: '',
          price: '0.57/DNAU',
          isHidden: false,
        },
        {
          id: 7,
          img: LegendHidden,
          rarelyNFT: '',
          name: '',
          price: '0.50/DNAU',
          isHidden: true,
        },
        {
          id: 8,
          img: LegendHidden,
          rarelyNFT: '',
          name: '',
          price: '0.50/DNAU',
          isHidden: true,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    if (screenViewPort) {
      let number = 0;
      switch (screenViewPort) {
        case ViewPort.DESKTOP:
          number = 3;
          break;
        case ViewPort.TABLET:
          number = 2;
          break;
        case ViewPort.MOBILE:
          number = 1;
          break;
        default:
          break;
      }
      setSlidesView(number);
    }
  }, [screenViewPort]);
  const handleSlider = (id) => {
    setSlideSelected(id);
  };
  return (
    <GeneralCarrousel>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={slidesView}
        navigation
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        /*  onSwiper={(swiper) => console.log('swiper', swiper)}
        onSlideChange={(w) => console.log('slide change', w)} */
        style={{ height: '100%', padding: '0 40px' }}
        sl
      >
        {legends.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <StylesCardCarrousel
                /* className={`${slideSelected === item.id ? 'active' : ''}`} */
                /*  onClick={() => handleSlider(item.id)} */
                blurEffect={item.isHidden}
              >
                <div>
                  <img src={item.img} alt="Toro" className={item.isHidden ? 'img-hidden' : ''} />
                  <div className="letter">
                    <span>{item.rarelyNFT}</span>
                  </div>
                  <div className="pos-absolute">
                    {/*  <p className="name">{item.name}</p> */}
                    {/*  <p className="price">{item.price}</p>
                    <Button
                      color="var(--primary-color)"
                      text={t('buyNFT')}
                      bgColorHover="var(--primary-color-medium)"
                    ></Button> */}
                  </div>
                </div>
              </StylesCardCarrousel>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </GeneralCarrousel>
  );
};
