export const translationsES = {
  /* Section Coming Soon */
  'COMING SOON': {
    title: 'MUY PRONTO',
    description: 'Hola! Estamos lanzando el sitio web más ',
    legendary: 'legendario. ',
    descriptionEnd: '',
    'Stay tune': 'Manténgase en sintonía.',
    /* Form */
    placeholderEmail: 'TU CORREO ELECTRONICO',
    'notify me': 'NOTIFICARME',
  },
  /* WEBSITE */
  website: {
    hero: {
      description: 'Bienvenido al juego real de NFT´s… escoge tu leyenda, combate y gana',
    },
    scroll: {
      text: 'Desliza y explora',
    },
    menu: {
      howToPlay: 'Cómo jugar',
      howToWin: 'Cómo ganar',
      team: 'Equipo',
      contactUs: 'Contactar',
      search: 'Buscar',
    },
    howPlay: {
      firstText: 'Si no juegas',
      secondText: 'no ganas',
      description: 'Nuestro juego no se limita a unos pocos clics, aquí valoramos tu habilidad como jugador.',
      move: 'MOVER',
      attack: 'ATACAR',
      keyboard: 'Usa tu teclado para interactuar',
      buy: 'Compra Rolling Calf ® →',
    },
    cardPlay: {
      mainNormal: 'Descubre el maravilloso mundo de DNA Legends, aquí ',
      mainBold: 'todos pueden ganar.',
      descriptionLeft: 'Conviértete en una leyenda y combate contra los poderosos mutantes controlados por Cthulhu.',
      hashGame: '',
      hashPlay: '#Juega',
      hashLegends: '#Leyenda',
      hashFreeToPlay: '#Gratis',
      descriptionRight: '¿Tienes lo que se necesitas para ser una leyenda? Gana tokens y NFT´s jugando o invirtiendo',
      hashWin: '#Gana',
      hashInvestment: '#Inversión',
      benefits: 'Beneficios de la inversión anticipada →',
      buy: 'Comprar NFT’s →',
    },
    gameAll: {
      titleUp: 'UN JUEGO',
      titleDown: 'PARA TODOS',
      descriptionPlayer: 'Compra y gana con tu NFT derrotando a otros jugadores.',
      questionPlayer: '¿Serás una Leyenda?',
      descriptionLegend:
        'Consigue el patrocinio de un JEFE y combate con sus NFT para obtener ganancias y repartirlas.',
      linkLegend: '¿Cómo ser una leyenda? →',
      descriptionBoss: 'Alquile sus NFT a un jugador LEGENDS para obtener ganancias y luego dividirlas.',
      financial: 'Análisis financiero →',
      player: 'JUGADOR',
      legend: 'LEYENDA',
      boss: 'JEFE',
    },
    nft: {
      maps: 'mapas',
      accesories: 'accesorios',
      rarity: 'rareza',
      info: 'Compra / vende increíbles NFT de leyendas o mapas de diferentes niveles.',
      openMarketplace: 'Ir a Marketplace →',
      titleRarity: 'Rareza de NFT',
      rare: 'RARO',
      lessRare: 'COMUN',
      created: 'Posibilidad de ser creado',
      moreInfo: 'Más Información →',
    },
    dashboardToken: {
      hasPercent: 'tiene un porcentaje de',
      staking: 'Recompensa',
      privateSale: 'Venta Privada',
      presale: 'Preventa',
      externalCost: 'Costos externos',
      DNAFoundation: 'Fundación DNA',
      liquidityProvision: 'Fondo de Provisión de Liquidez',
      launchPool: 'Launchpool',
      referralProgram: 'Programa de referencia',
      exchangeHouses: 'Casas de cambio',
      moreInfo: 'Para obtener más información, explora el',
      title: 'TOKENOMIA',
      description: '¿Cómo se distribuye nuestra economía?',
    },
    partners: {
      title: 'Aliados',
      team: 'EQUIPO',
      a: 'UN',
      legends: 'DE LEYENDAS',
    },
    alerts: {
      titleWhitepapper: 'Estamos escribiendo las reglas del futuro...',
      textWhitepapper: 'Muy pronto en nuestro WhitePaper',
      titleGameMobile: 'Alerta: para conectarte cambia tu dispositivo.',
      textGameMobile: 'DNA Legends te dará la mejor experiencia de juego desde tu PC.',
    },
  },
  formLead: {
    subtitleOne: 'Nos comunicamos contigo',
    subtitleTwo: 'Registro de acceso anticipado',
    name: 'Nombre',
    mail: 'Correo Electronico',
    countryCode: 'Indicativo',
    phone: 'Celular',
    accept: 'Acepta',
    terms: 'nuestra política de privacidad y tratamiento de datos',
    send: 'INFORMAME',
    successLegend: 'Eres un legend',
    thanks: 'Gracias por registrarse',
  },
  /* Ad blocker */
  adBlock: {
    text: 'TIENES UN BLOQUEADOR DE ANUNCIOS, DESACTIVALO PARA PODER CONTINUAR',
  },
  /* Footer */
  footer: {
    title: 'Sitio web de DNA Universe. Todos los derechos reservados',
    legends: '®DNA Legends 2022. Todos los derechos reservados',
    followUs: 'Siguenos',
  },
  pagecounter: {
    textFreeToPlay: 'La batalla de la guerra contra los mutantes',
    textFreeToPlayTwo:
      'Los mutantes controlados por Cthulhu salieron muy destructivos para derrotarnos como leyendas por lo que estamos mejorando nuestros poderes para poder contraatacar y derrotar a las fuerzas de Cthulhu.',
    left: 'restantes',
    days: 'dias',
    day: 'dia',
    hours: 'horas',
    hour: 'hora',
    minutes: 'minutos',
    minute: 'minuto',
    seconds: 'segundos',
    second: 'segundo',
  },
  login: {
    title: 'INICIAR SESION',
    email: 'CORREO ELECTRONICO',
    password: 'CONTRASEÑA',
    rememberMe: 'Recuerdame',
    forgotPass: 'Olvide mi contraseña',
    enter: 'INGRESAR',
    or: 'o',
    connect: 'CONECTAR WALLET',
    noAccount: 'Sin cuenta',
    createFree: 'Registrate Gratis',
    backHome: 'Ir a Pagina Principal',
    recoveryPass: 'RECUPERACIÓN DE LA CONTRASEÑA',
    send: 'ENVIAR',
    backLogin: 'Ir a iniciar sesión',
    register: 'REGISTRARSE',
    name: 'Nombre',
    lastname: 'Apellido',
    username: 'Usuario',
    confirm: 'Confirmar',
    characters: 'CARACTERES',
    capital: 'LETRA MAYUSCULA',
    number: 'NUMERO',
    special: 'CARACTER ESPECIAL',
    conditions: 'Al hacer clic en “Crear cuenta” usted acepta los términos y condiciones de lo siguiente',
    terms: 'Condiciones de servicio y política de privacidad',
    create: 'CREAR CUENTA',
    present: 'Presenta',
    loading: 'Cargando',
    waitMoment: 'Wait a moment',
    great: 'GENIAL',
    reviewEmail: 'Revise y siga los pasos enviados a su correo electrónico',
    verifying: 'Verificando datos...',
    welcome: 'BIENVENIDO',
    goLegend: 'Está listo para que ganes ¡Sé una leyenda!',
    invalidEmail: 'Correo con formato invalido',
    passwordMin: 'Password debe contener minimo 8 caracteres',
    usernameMin: 'Usuario debe contener minimo 8 caracteres',
    verifiyinRegister: 'Verificando datos para el registro, espera un momento...',
    registered: 'Registrado exitosamente',
    userLogin: 'Usuario registrado, por favor logueate',
    errorRegister: 'Hubo un error al registrar usuario',
    warning: 'ADVERTENCIA',
    validatePass: 'Valida que el password cumpla con los requerimientos',
    errorSendEmail: 'No se envio el correo',
    strong: 'FUERTE',
    weak: 'DEBIL',
    passDontMatch: 'Los passwords no coinciden, verifica nuevamente',
    nameLastnameLength: 'Nombre y Apellido debe contener al menos 3 caracteres',
    lastnameLength: 'Apellido debe contener al menos 3 caracteres',
    nameLength: 'Nombre debe contener al menos 3 caracteres',
    updatePass: 'Contraseña actualizada!',
    errorUpdatePass: 'No se pudo actualizar contraseña',
    newPass: 'NUEVA CONTRASEÑA',
  },
  /* Common */
  'Contact us': 'Contactenos',
  playNow: 'JUGAR',
  buyNFT: 'COMPRAR',
  letsGo: 'EMPEZAR',
  team: 'Equipo',
  goLogin: 'Iniciar Sesión',
  /* msg Backend */
  'Incorrect email or password': 'Email y/o Contraseña invalidos',
};
