import styled from 'styled-components';
import { device } from '../../../constants/device';
import HeroBG from '../../../assets/img/BackgroundHero.png';
import Map from '../../../assets/img/Map.png';

export const StylesHome = styled.section`
  [id] {
    scroll-margin-top: 100px;
  }
  color: white;
  background-color: var(--bg-dark-home);
`;
export const HeroStyles = styled.section`
  background: url(${HeroBG}) no-repeat;

  background-size: 100% 100%;
  padding: 230px 20px 140px;
  position: relative;
  & .pos-relative {
    position: relative;
  }
  & .text-title {
    position: relative;
    transform: rotate(-5deg);
    & > .first-title {
      font-family: 'Press Start 2P';
      color: var(--primary-color);
      font-size: var(--fs-medium-large);

      z-index: 5;

      position: absolute;
      top: -21px;
      left: 5px;
      & p {
        filter: drop-shadow(2px 4px 6px black);
      }
    }
    & > .second-title {
      font-family: 'Obrazec';
      color: var(--secondary-color);
      font-size: var(--fs-large-two);
      z-index: 3;
    }
  }
  & .text-description {
    font-family: 'Century Gothic', sans-serif;
    font-size: 20px;
    max-width: 410px;
    margin-top: 1.5rem;
  }
  & .btn-hero {
    margin-top: 4rem;
    max-width: 280px;
  }

  & .section-mutant {
    position: absolute;
    top: -100px;
    left: calc(42vw - 336px);
    z-index: 2;
    & img {
      width: var(--img-hero);
      filter: drop-shadow(0px 0px 30px rgba(0, 0, 0, 0.4));
    }
  }
  & .arrow-side {
    position: absolute;
    bottom: -100px;
    left: -50px;
    display: flex;
    align-items: center;
    max-height: 100px;
    transition: transform 0.5s ease;
    transform: translateY(0px);
    z-index: 2;
    & img {
      height: 288px;
    }
    & .text-scroll {
      width: 140px;
      opacity: 0.6;
    }
    &:hover {
      transform: translateY(100px);
    }
  }
  @media ${device.laptop} {
    & .section-mutant {
      left: calc(48vw - 236px);
    }
    & .arrow-side {
      bottom: -142px;
      left: -24px;
      & img {
        height: 188px;
      }
    }
  }
  @media ${device.tabletL} {
    & .section-mutant {
      left: calc(48vw - 100px);
    }
    & .arrow-side {
      display: none;
    }
  }
  @media ${device.tablet} {
    padding: 230px 10px 40px;
    height: 840px;
    z-index: 9;
    & .section-mutant {
      position: static;
    }
    & .section-info {
    }
    & .pos-relative {
      text-align: center;
    }
    & .text-title {
      position: relative;
      transform: rotate(-5deg);
      & > .first-title {
        right: 5px;
      }
    }
    & .text-description {
      margin: 1.5rem auto 1rem;
    }
    & .btn-hero {
      margin: 4rem auto 1rem;
    }
    & .row {
      display: block;
    }
  }
`;

export const SocialMedia = styled.section`
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
  z-index: 4;
  @media ${device.tablet} {
    justify-content: center;
  }
`;
export const HowToPlay = styled.section`
  background-color: var(--yellow-color);

  & > div {
    background-color: var(--bg-dark-home);
    -webkit-clip-path: polygon(0 0, 100% 10%, 100% 101%, 0% 101%);
    clip-path: polygon(0 2%, 100% 10%, 100% 101%, 0% 101%);
    padding: 120px 20px 200px;
  }

  & .title-section {
    max-width: 590px;
    margin-bottom: 3rem;
    & .title {
      font-family: 'Obrazec';
      color: white;
      font-size: var(--fs-large-three);
      & span {
        color: var(--secondary-color);
      }
    }
    & .text-description {
      font-size: 20px;
      max-width: 410px;
    }
  }
  & .row {
    align-items: center;
    text-align: center;
    row-gap: 3rem;
    & .mutant {
      position: relative;
      & .reverse {
        transform: scale(-1, 1);
      }
      & img {
        max-width: 484px;
      }
      & .shadow-mutant {
        position: absolute;
        width: 294px;
        height: 82px;
        left: 0px;
        right: 0px;
        margin: auto;
        bottom: -40px;
        background: #4d4d4d;
        filter: blur(25px);
        z-index: -1;
      }
    }
    & .keyboard {
      z-index: 2;

      & img {
        width: 310px;
      }
      & .grid-keyboard {
        display: grid;
        width: 390px;
        row-gap: 2rem;
        margin: 0 auto 3rem;
        & .key {
          width: 75px;
          height: 75px;
          font-family: 'Press Start 2P';
          font-size: 30px;
          color: black;
          background: #b1c5d1;
          box-shadow: 0px 20px 0px 0 #545e6b, inset 0px 0px 0px 0px #545e6b;
          transition: box-shadow 0.2s linear;
          display: flex;
          justify-content: center;
          align-items: center;
          &:nth-child(1) {
            grid-area: 1 / 2;
          }
          &:nth-child(2) {
            grid-area: 2 / 1;
          }
          &:nth-child(3) {
            grid-area: 2 / 2;
          }
          &:nth-child(4) {
            grid-area: 2 / 3;
          }
          &.press {
            box-shadow: 0px 0px 0px 0px #545e6b, inset 0px 0px 10px 4px #545e6b;
          }
        }
      }
      & .text-item {
        padding-top: 0.5rem;
        font-family: 'Press Start 2P';
        font-size: 20px;
      }
    }
  }
  & .action {
    text-align: start;
    padding: 0 2rem;
    & .text-keyboard {
      max-width: 358px;
    }
    & p {
      font-size: var(--fs-normal);
    }
    & .btn-play {
      margin-top: 2rem;
      display: flex;
      & button {
        margin-right: 1rem;
      }
    }
  }
  & .arrow-side {
    position: absolute;
    bottom: 50px;
    right: -50px;
    display: flex;
    align-items: center;
    max-height: 100px;
    transition: transform 0.5s ease;
    transform: translateY(0px);
    z-index: 2;
    & img {
      height: 288px;
    }
    &:hover {
      transform: translateY(100px);
    }
  }
  @media ${device.tabletL} {
    & > div {
      -webkit-clip-path: polygon(0 0, 100% 6%, 100% 101%, 0% 101%);
      clip-path: polygon(0 2%, 100% 6%, 100% 101%, 0% 101%);
      padding: 100px 20px 200px;
    }
    & .action {
      padding-top: 2.5rem;
    }
    & .row {
      & .mutant {
        & img {
          transform: scale(-1, 1);
        }
      }
    }
  }

  @media ${device.tablet} {
    & > div {
      padding: 550px 20px 200px;
    }
    & .row {
      & .keyboard {
        display: none;
      }
    }
  }
  @media ${device.mobileL} {
    & .row {
      & .keyboard {
        display: none;
      }
    }
    & .action {
      & .btn-play {
        flex-direction: column;
        gap: 1.5rem;
        align-items: center;
        & button {
          width: 100%;
        }
      }
      & .text-keyboard {
        visibility: hidden;
      }
    }
  }
  @media ${device.mobileM} {
    & > div {
      padding: 380px 20px 200px;
    }
  }
`;

export const CardToPlay = styled.section`
  background-color: var(--bg-dark-home);
  padding: 3rem 1rem 150px;
  & .title-main {
    margin: auto;
    max-width: 736px;
    & p {
      font-size: var(--fs-medium);
      & span {
        font-weight: bold;
      }
    }
  }
  & .card-main {
    padding: 2rem;
    overflow: hidden;
    margin: 3rem 1rem;
    min-height: 350px;
    position: relative;
    background: linear-gradient(110deg, #1c242e 72%, #38f2e4 77%);
    box-shadow: 0 0 3px 0px #000000a8;
    transition: background 0.5s ease;
    &:hover {
      background: linear-gradient(110deg, #1c242e 56%, #38f2e4 63%);
      & .img-card {
        transform: scale(1.2) translateY(-25px) translateX(-25px);
        &.left {
          transform: scale(1.2) translateY(-25px) translateX(25px);
        }
      }
      & .title {
        color: var(--primary-color);
      }
    }
    &.end-text {
      text-align: end;
      justify-content: flex-end;
      transition: background 0.5s ease;
      background: linear-gradient(254deg, #1c242e 72%, rgba(255, 64, 142, 0.7) 77%);
      &:hover {
        background: linear-gradient(254deg, #1c242e 56%, rgba(255, 64, 142, 0.7) 63%);
        & .title {
          color: var(--secondary-color);
        }
      }
    }
    & .title {
      font-size: var(--fs-medium-large-two);
      font-family: 'Obrazec';
      margin-bottom: 2.5rem;
      transition: color 0.5s linear;
    }
    & .description {
      font-size: var(--fs-normal);
      margin-bottom: 2rem;
    }
    & .hashtag {
      font-size: var(--fs-small);
      opacity: 0.5;
      margin-bottom: 2rem;
      & span {
        margin-right: 0.6rem;
      }
    }

    & .text-card {
      flex-basis: 60%;
      z-index: 1;
    }

    & .img-card {
      position: absolute;
      right: 0;
      bottom: -5px;
      transition: transform 0.5s ease;
      transform: scale(1) translateY(0px) translateX(0px);
      z-index: 0;
      &.left {
        left: 0;
        right: auto;
      }
    }
  }

  & .links {
    & > * {
      display: flex;
      margin-left: auto;
      padding-bottom: 1rem;
    }
  }
  @media ${device.laptop} {
    & .card-main {
      padding: 1rem;
      &:hover {
        & .img-card {
          transform: scale(1.1) translateY(-12px) translateX(-12px);
          &.left {
            transform: scale(1.1) translateY(-12px) translateX(12px);
          }
        }
      }
      & .img-card {
        width: 275px;
      }
    }
  }
  @media ${device.tabletL} {
    & .card-main {
      padding: 2rem;
      &:hover {
        & .img-card {
          transform: scale(1.2) translateY(-12px) translateX(-12px);
          &.left {
            transform: scale(1.2) translateY(-12px) translateX(12px);
          }
        }
      }
      & .img-card {
        width: 320px;
      }
    }
  }
  @media ${device.tablet} {
    & .card-main {
      padding: 1rem;
      &:hover {
        & .img-card {
          transform: scale(1.1) translateY(-12px) translateX(-12px);
          &.left {
            transform: scale(1.1) translateY(-12px) translateX(12px);
          }
        }
      }
      & .img-card {
        width: 275px;
      }
    }
    & .links {
      & > * {
        margin-right: auto;
      }
    }
  }
  @media ${device.mobileL} {
    & .card-main {
      align-items: flex-end;
      min-height: 490px;
      margin: 3rem 0rem;
      padding: 0;
      background: linear-gradient(110deg, #1c242e 30%, #38f2e4 40% 70%, #1c242e 80%);
      &:hover {
        background: linear-gradient(110deg, #1c242e 30%, #38f2e4 40% 70%, #1c242e 80%);
        & .img-card {
          transform: scale(1.1) translateY(-12px) translateX(-12px);
          &.left {
            transform: scale(1.1) translateY(-12px) translateX(12px);
          }
        }
      }
      &.end-text {
        text-align: start;
        background: linear-gradient(254deg, #1c242e 30%, rgba(255, 64, 142, 0.7) 40% 70%, #1c242e 80%);
        &:hover {
          background: linear-gradient(254deg, #1c242e 30%, rgba(255, 64, 142, 0.7) 40% 70%, #1c242e 80%);
        }
      }
      & .text-card {
        flex-basis: 100%;
        padding: 15rem 1rem 1rem;
        z-index: 1;
        background: linear-gradient(0deg, #1c242e, transparent);
        backdrop-filter: blur(10px);
      }
      & .title {
        margin-top: 3rem;
      }
      & .img-card {
        right: 0;
        top: 0;
        bottom: auto;
        z-index: 2;
        & img {
          filter: opacity(0.8);
        }

        &.left {
          left: 0;
          right: auto;
        }
      }
      & .btn-play {
        & button {
          width: 100%;
        }
      }
    }
  }
`;

export const GameForAll = styled.section`
  padding: 20px 0 160px;
  background-color: var(--bg-dark-home);
  position: relative;
  & .title-main {
    text-align: center;
    font-family: 'Obrazec';
    color: var(--yellow-color);
    font-size: var(--fs-large-four);
    & .title {
      position: relative;
      width: 285px;
      margin: auto;
      & img {
        position: absolute;
        top: 0;
        right: 300px;
        height: 136px;
        width: 641px;
        &.right {
          right: auto;
          left: 200px;
          top: 10px;
        }
        &.right-title {
          display: none;
          transform: scale(-1);
          left: 300px;
        }
      }
    }
  }

  & .arrow-side {
    position: absolute;
    bottom: 50px;
    left: -50px;
    display: flex;
    align-items: center;
    max-height: 100px;
    transition: transform 0.5s ease;
    transform: translateY(0px);
    z-index: 2;
    & img {
      height: 288px;
    }
    &:hover {
      transform: translateY(100px);
    }
  }
  @media ${device.tabletL} {
    & .row.justify-center {
      justify-content: center;
    }
  }
  @media ${device.tablet} {
    & .title-main {
      & .title {
        & img {
          &.right {
            display: none;
          }
        }
      }
    }
  }
  @media ${device.mobileL} {
    & .title-main {
      & .title {
        & img {
          height: 50px;
          width: 300px;
          top: 42px;
          right: 250px;
          &.right-title {
            display: block;
            left: 250px;
          }
        }
      }
    }
  }
`;

export const NFT = styled.section`
  min-height: 800px;
  padding: 50px 20px 160px;
  background-color: var(--bg-dark-home);
  & .main-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Obrazec';
    & .title-main {
      color: var(--primary-color);
      font-size: var(--fs-large-four);
    }
    & .tabs {
      display: flex;

      & .item-tab {
        margin-left: 2rem;
        font-size: var(--fs-medium);
        color: var(--gray-dark-text);
        position: relative;
        &:hover {
          cursor: pointer;
          color: var(--gray-text);
          opacity: 0.8;
        }
        &.active {
          color: var(--gray-text);
        }
        &:not(:last-child)::after {
          content: '';
          position: absolute;
          right: -18px;
          top: -10px;
          height: 40px;
          width: 3px;
          background-color: var(--gray-dark-text);
        }
      }
    }
  }
  & .info-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    & .text-info {
      display: flex;
      align-items: center;
      font-size: 20px;

      & img {
        width: 30px;
        margin-right: 1rem;
      }
      & p {
        max-width: 434px;
      }
    }
  }

  & .carrousel {
    margin: 3rem 0;
  }

  & .rarity-section {
    margin: 3rem 0;
    & .title {
      font-size: var(--fs-medium);
    }
    & .row {
      margin: 2rem 0;
      color: var(--secondary-color);
      align-items: flex-end;
      & > * {
        flex: 1;
      }

      &.indicator {
        font-family: 'Obrazec';
        font-size: 40px;
      }
    }
    & .rare-text {
      font-family: 'Obrazec';
      color: white;
      font-size: var(--fs-medium-large-two);
      display: flex;
      justify-content: space-between;
      & > div:last-child {
        opacity: 0.6;
      }
    }
    & .font-size {
      font-family: 'Press Start 2P';
      & > p:nth-child(1) {
        font-size: 80px;
      }
      & > p:nth-child(2) {
        font-size: 70px;
      }
      & > p:nth-child(3) {
        font-size: 60px;
      }
      & > p:nth-child(4) {
        font-size: 50px;
      }
      & > p:nth-child(5) {
        font-size: 40px;
      }
      & > p:nth-child(6) {
        font-size: 30px;
      }
    }
    & .degrade {
      & > p:nth-child(2) {
        opacity: 0.9;
      }
      & > p:nth-child(3) {
        opacity: 0.8;
      }
      & > p:nth-child(4) {
        opacity: 0.7;
      }
      & > p:nth-child(5) {
        opacity: 0.6;
      }
      & > p:nth-child(6) {
        opacity: 0.5;
      }
    }
    & .btn-info {
      padding: 3rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
      & p {
        font-size: 20px;
      }
    }
  }
  @media ${device.tablet} {
    & .main-section {
      flex-direction: column;
      gap: 2rem;
    }
    & .info-section {
      display: block;
      & > div:last-child {
        margin-top: 2rem;
        text-align: end;
      }
    }
    & .rarity-section {
      & .row {
        &.indicator {
          font-size: 24px;
        }
      }
      & .font-size {
        & > p {
          font-size: 30px !important;
        }
      }
    }
  }
  @media ${device.mobileL} {
    & .rarity-section {
      & .row {
        &.indicator {
          font-size: 18px;
        }
      }
      & .btn-info {
        padding: 3rem 0rem;
        & p {
          font-size: 16px;
        }
      }
    }
  }
  @media ${device.mobileM} {
    & .btn-info {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export const DashboardToken = styled.section`
  padding: 20px 20px 160px;
  background-color: var(--bg-dark-home);
  & .main-section {
    display: flex;
    align-items: center;
    gap: 2rem;

    & .title-main {
      font-family: 'Obrazec';
      color: var(--secondary-color);
      font-size: var(--fs-large-four);
    }
    & .text-description {
      font-size: 20px;
      max-width: 333px;
    }
  }
  & .graphic {
    padding: 5rem 0;
    min-height: 400px;

    & .fusioncharts-container > svg {
      background-color: transparent !important;
      & > [class^='raphael-group-']:first-of-type > g:first-child {
        opacity: 0;
      }
      & > [class^='raphael-group-']:last-of-type {
        display: none;
      }
    }
  }
  & .btn-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: end;
    & p {
      padding-bottom: 1rem;
    }
    & p {
      font-size: 20px;
    }
  }
  & text {
    //fill: white;
    font-size: var(--fs-normal) !important;
    //opacity: 0.5;
    font-family: 'Century Gothic', sans-serif !important;
  }
  & .graphic-table {
    display: none;
  }
  @media ${device.tabletL} {
    & .graphic {
      padding: 5rem 1rem;
    }
  }
  @media ${device.tablet} {
    & .graphic {
      display: none;
    }
    & .graphic-table {
      display: flex;
      padding: 2rem 0;
      & .text-align {
        display: flex;
        align-items: center;
      }
      & .line-total {
        width: 20px;
        height: 100%;
        border: 1px solid var(--secondary-color);
        border-left: none;
      }
      & p {
        transform: rotate(90deg);
        font-family: 'Press Start 2P';
        font-size: 20px;
        color: var(--secondary-color);
      }
    }
    & .btn-info {
      text-align: center;
    }
  }
  @media ${device.mobileL} {
    & .main-section {
      flex-direction: column;
      gap: 1rem;
      text-align: center;
    }
  }
`;

export const ItemGraphic = styled.div`
  color: ${(props) => props.color || 'white'};
  padding-bottom: 0.6rem;
  font-size: 20px;
  & .square {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.color || 'white'};
    margin-right: 1rem;
  }
  @media ${device.mobileM} {
    font-size: 15px;
  }
`;
export const Partners = styled.section`
  padding: 20px 20px 160px;
  background-color: var(--bg-dark-home);
  & .main-section {
    display: flex;
    justify-content: center;
    & .title-main {
      font-family: 'Obrazec';
      color: white;
      font-size: 80px;
    }
  }
  & .logo-partners {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 50px;
    gap: 2rem;
    & img {
      transition: transform 1s linear, filter 1s linear;
      max-width: 300px;
      &:hover {
        transform: scale(1.2);
        filter: drop-shadow(0px 0px 3px var(--primary-color));
      }
    }
  }
  @media ${device.laptop} {
  }
`;

export const TeamSection = styled.section`
  padding: 20px 0 160px;
  background-color: var(--bg-dark-home);
  & .title {
    & .fontStyle {
      font-family: 'Obrazec';
      color: var(--primary-color);
      font-size: 100px;
      margin-right: 2.5rem;
    }
    & .text-legends {
      font-family: 'Press Start 2P';
      color: var(--secondary-color);
      font-size: 50px;
    }
  }
  & .grid-team {
    padding-top: 50px;
    display: grid;
    /* Grid de 8c x ? */
    grid-template-columns: repeat(8, 150px);
    gap: 1rem;
    justify-content: center;
    .our-team {
      text-align: center;
      position: relative;
      color: #fff;
      min-width: 100px;
      transition: transform 0.5s linear;
      transform-origin: top left;
      width: 150px;
      height: 150px;
      font-family: 'Century Gothic', sans-serif;
      box-shadow: 0 0 7px 0px #00000040;
      &:nth-child(8n) {
        transform-origin: top right;
      }
      &.up {
        transform-origin: bottom left;
      }
      &:hover {
        transform: scale(2.15);
        z-index: 10;
        filter: grayscale(0);
      }
    }
    .our-team img {
      width: 150px;
      height: 150px;
    }
    .our-team .content {
      background: rgba(0, 0, 0, 0.2);
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      text-align: center;
      transform: perspective(400px) rotateX(-90deg);
      transform-origin: center top 0;
      transition: all 0.7s linear 0s;
      opacity: 0;
    }
    .our-team:hover .content {
      opacity: 1;
      transform: perspective(400px) rotateX(0deg);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
    .our-team .content .title {
      margin: 0;
      display: block;
      font-size: 15px;
      font-weight: 600;
    }
    .our-team .content .post {
      margin: 10px 0 0 0;
      display: block;
      font-size: 10px;
      line-height: 1.1;
    }
  }
  & .contact {
    &.up {
      text-align: end;
      padding: 20px 180px;
    }
    &.down {
      padding: 20px 180px;
    }
    & p:first-child {
      font-size: 30px;
      font-family: 'Century Gothic Bold';
    }
    & p:last-child {
      font-size: 20px;
    }
  }
  & .bottom-text {
    font-family: 'Press Start 2P';
    font-size: 50px;
    color: var(--primary-color);
    text-align: end;
  }
  @media ${device.desktop} {
    & .grid-team {
      grid-template-columns: repeat(8, 120px);
      & .our-team {
        width: 120px;
        height: 120px;
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }
  @media ${device.tabletL} {
    display: none;
  }
`;

export const FormSection = styled.section`
  min-height: 800px;
  padding: 20px 20px 160px;
  background-color: var(--bg-dark-home);
  & .bg-form {
    background: url(${Map}) no-repeat;
  }
  @media ${device.mobileL} {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const PlayNowStyles = styled.section`
  div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--bg-loading);
    z-index: 100;
    transform: translateX(100vw);
    transition: transform 1.3s linear;
    &.active {
      transform: translateX(0);
    }
  }
`;
