import styled from 'styled-components';

export const Svg = styled.svg`
  margin-right: 0.5rem;
  cursor: ${(props) => (props.link ? 'pointer' : 'default')};
  &:hover {
    fill: ${(props) => props.hover};
    & path {
      fill: ${(props) => props.hover};
    }
  }
`;
