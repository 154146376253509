import styled, { keyframes } from 'styled-components';
import { device } from '../../constants/device';
/* Animations */

// Create the keyframes
const animationRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;
export const StylesFooter = styled.section`
  max-width: 1480px;
  color: var(--gray-text);
  margin: auto;
  padding: 40px 0px;
  text-align: center;
  background-color: var(--bg-dark-home);
  & > .row {
    row-gap: 4rem;
    align-items: center;
  }

  & .lineHorizontal {
    height: 1px;
    background-color: var(--line-horizontal);
    width: 1128px;
    margin: 2rem auto;
    &.display-show-m {
      display: none;
    }
  }
  & .title-section {
    font-size: 40px;
  }
  & .container-links {
    display: flex;
    gap: 2rem;
  }
  & .section-links {
    font-size: 20px;
    & > * {
      padding-top: 10px;
    }
  }

  & .footer-info {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: left;
  }
  & .text-right {
    text-align: right;
    padding-right: 2rem;
  }
  @media ${device.tablet} {
    & .lineHorizontal {
      &.display-show-m {
        display: block;
      }
    }
  }
`;

export const StylesFooterLegends = styled(StylesFooter)`
  & .item-text.mobile {
    display: none;
  }

  @media ${device.tabletL} {
    & .text-right {
      text-align: center;
      padding-right: 0;
    }
  }
  @media ${device.mobileL} {
    & .footer-info {
      justify-content: center;
      text-align: left;
      flex-direction: column;
      gap: 3rem;
      & .section-links:last-child {
        text-align: right;
      }
    }
    & .item-text {
      display: none;
      &.mobile {
        display: block;
        font-size: 20px;
      }
    }
  }
`;
export const SocialMedia = styled.section`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 3rem;
  z-index: 4;
  padding: 2rem;
  justify-content: flex-end;
  @media ${device.tabletL} {
    justify-content: center;
  }
  @media ${device.mobileL} {
    margin-top: 0;
  }
`;
