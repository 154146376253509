import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationsEN } from './English';
import { translationsES } from './Spanish';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationsEN,
  },
  es: {
    translation: translationsES,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: /*  navigator.language || navigator.userLanguage || */ 'en',
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
/* 
  <p>{t(['Welcome to React', "Welcome Guys"])}</p>
  <p>{t(['admin.hello', "Hi"])}</p>
  <button onClick={() => i18n.changeLanguage('es')}>es</button>
    <button onClick={() => i18n.changeLanguage('en')}>en</button>
  */
export default i18n;
