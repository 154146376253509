import styled, { keyframes } from 'styled-components';

/* Animations */

// Create the keyframes
const animationRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const StylesLogo = styled.section`
  width: ${(props) => props.width || '80px'};
  opacity: ${(props) => (props.opacity ? 0.5 : 1)};
  & .image-section {
    overflow: hidden;
    position: relative;
    & .logo-dna {
      position: absolute;
      inset: 0;
      display: flex;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      & img {
        width: ${(props) => props.widthImg || '53px'};
        height: ${(props) => props.widthImg || '53px'};
      }
    }
    & .text-dna {
      animation: ${animationRotate} ${(props) => (props.move === 'fast' ? '8s' : '20s')}
        ${(props) => (props.move === 'fast' ? 'ease' : 'linear')} infinite;
      animation-timing-function: ${(props) => (props.transitionAlternative ? 'ease' : 'linear')};
      width: ${(props) => props.width || '80px'};
      height: ${(props) => props.width || '80px'};
    }
  }
`;
