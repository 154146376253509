export const translationsEN = {
  /* Section Coming Soon */
  'COMING SOON': {
    title: 'COMING SOON',
    description: 'Hi! we are launching the most ',
    legendary: 'legendary ',
    descriptionEnd: 'website.',
    'Stay tune': 'Stay tune.',
    /* Form */
    placeholderEmail: 'YOUR EMAIL',
    'notify me': 'NOTIFY ME',
  },
  /* WEBSITE */
  website: {
    hero: {
      description: 'Welcome to the real NFT game... obtain Leyenda, Fight & Win!',
    },
    scroll: {
      text: 'Scroll to explore',
    },
    menu: {
      howToPlay: 'How To Play',
      howToWin: 'How To Win',
      team: 'Team',
      contactUs: 'Contact us',
      search: 'Search',
    },
    howPlay: {
      firstText: 'No Play',
      secondText: 'No Gain',
      description: `Clicks aren't what matter here, only your abilities as a true gamer`,
      move: 'MOVE',
      attack: 'ATTACK',
      keyboard: 'Use your keyboard to interact',
      buy: 'Buy a Rolling Calf ® →',
    },
    cardPlay: {
      mainNormal: 'Discover the magnificent world of DNA Legends. Here, ',
      mainBold: 'anyone can win!',
      descriptionLeft: 'Become a Legend and fight against the powerful mutant forces commanded by Cthulhu.',
      hashGame: '#Game',
      hashPlay: '#Play',
      hashLegends: '#Legends',
      hashFreeToPlay: '#Free to play',
      descriptionRight: 'Do you have what it takes to be a true Legend? Earn Tokens and NFTs by playing or investing',
      hashWin: '#Win',
      hashInvestment: '#Investment',
      benefits: 'Early Investor Benefits →',
      buy: 'Buy NFT’s →',
    },
    gameAll: {
      titleUp: 'A GAME',
      titleDown: 'FOR ALL',
      descriptionPlayer: 'Buy and win with your NFT by defeating other players.',
      questionPlayer: 'Will you be a Legend?',
      descriptionLegend:
        'Profit & Distribute your winnings by obtaining sponsorship from a BOSS and fight along with his NFT.',
      linkLegend: 'How to be a legend? →',
      descriptionBoss: 'Profit & Distribute your winnings by renting your NFT to a LEGENDS player.',
      financial: 'Financial Analysis →',
      player: 'PLAYER',
      legend: 'LEGEND',
      boss: 'BOSS',
    },
    nft: {
      maps: 'maps',
      accesories: 'accesories',
      rarity: 'rarity',
      info: 'Buy / Sell amazing NFTs from Legends or maps from various different levels.',
      openMarketplace: 'Open Marketplace →',
      titleRarity: 'NFT Rarity',
      rare: 'RARE',
      lessRare: 'LESS RARE',
      created: 'Change of being created',
      moreInfo: 'For more info →',
    },
    dashboardToken: {
      hasPercent: 'has a percentage of',
      staking: 'Staking Rewards',
      privateSale: 'Private sale',
      presale: 'Presale',
      externalCost: 'External Cost',
      DNAFoundation: 'DNA Foundation',
      liquidityProvision: 'Liquidity Provision Fund',
      launchPool: 'Launchpool',
      referralProgram: 'Referral Program',
      exchangeHouses: 'Exchange Houses',
      moreInfo: 'For more information, please explore our',
      title: 'Tokenomics',
      description: 'How to distribute our economy? ',
    },
    partners: {
      title: 'Partners',
      team: 'TEAM',
      a: 'A',
      legends: 'LEGENDS',
    },
    alerts: {
      titleWhitepapper: 'We are the ones writing the rules for the future…',
      textWhitepapper: 'Coming soon to our WhitePaper.',
      titleGameMobile: 'Alert: To connect please change your device.',
      textGameMobile: 'DNA Legends will provide you with the best gaming experience from your PC.',
    },
  },
  formLead: {
    subtitleOne: 'We can get in touch with you',
    subtitleTwo: 'Early Access Registration',
    name: 'Name',
    email: 'Email',
    countryCode: 'Country Code',
    phone: 'Phone',
    accept: 'Accept',
    terms: 'our Privacy and Data Processing Policy',
    send: 'SEND',
    successLegend: 'You are a legend',
    thanks: 'thanks for signing up',
  },
  /* Ad blocker */
  adBlock: {
    text: 'YOU HAVE AN AD BLOCKER, DISABLE IT TO CONTINUE.',
  },
  /* Footer */
  footer: {
    title: 'Website by DNA Universe. All Right Reserved',
    legends: '®DNA Legends 2022. All Right Reserved',
    followUs: 'Follow us',
  },
  pagecounter: {
    textFreeToPlay: 'The battle for the war against the mutants.',
    textFreeToPlayTwo:
      'The mutants have come out in destructive droves determined to defeat us as Legends! Therefore, we are improving our might to be able to counter attack and defeat the forces of Cthulhu.',
    left: 'left',
    days: 'days',
    day: 'day',
    hours: 'hours',
    hour: 'hour',
    minutes: 'minutes',
    minute: 'minute',
    seconds: 'seconds',
    second: 'second',
  },
  login: {
    title: 'LOGIN',
    email: 'EMAIL',
    password: 'PASSWORD',
    rememberMe: 'Remember me',
    forgotPass: 'Forgot my password',
    enter: 'ENTER',
    or: 'or',
    connect: 'CONNECT WALLET',
    noAccount: 'No account',
    createFree: 'Create one for free',
    backHome: 'Back To home',
    recoveryPass: 'PASSWORD RECOVERY',
    send: 'SEND',
    backLogin: 'Go back to Login',
    register: 'REGISTER',
    name: 'Name',
    lastname: 'Last name',
    username: 'USERNAME',
    confirm: 'CONFIRM',
    characters: 'CHARACTERS',
    capital: 'CAPITAL LETTER',
    number: 'NUMBER',
    special: 'SPECIAL CHARACTER',
    conditions: 'Clicking “Create account” you agree to the terms and conditions of the following',
    terms: 'Terms of services and Privacy Policy',
    create: 'CREATE ACCOUNT',
    present: 'Present',
    loading: 'Loading',
    waitMoment: 'Wait a moment',
    great: 'GREAT',
    reviewEmail: 'Review and follow the steps sent to your email',
    verifying: 'Verifiying data...',
    welcome: 'WELCOME',
    goLegend: 'Is ready for you to win Go be a Legend!',
    invalidEmail: 'Invalid mail format',
    passwordMin: 'Password must contain at least 8 characters',
    usernameMin: 'Username must contain at least 8 characters',
    verifiyinRegister: 'Verifiying data for Register, Wait a moment...',
    registered: 'Registered',
    userLogin: 'User Register, Please Login',
    errorRegister: 'There was an error registering user',
    warning: 'WARNING',
    validatePass: 'Validate that the password meets the requirements',
    errorSendEmail: 'No mail sent',
    strong: 'STRONG',
    weak: 'WEAK',
    passDontMatch: 'Passwords do not match, check again',
    nameLastnameLength: 'First and Last Name must contain at least 3 characters',
    lastnameLength: 'Last name must contain at least 3 characters',
    nameLength: 'Name must contain at least 3 characters',
    updatePass: 'Password has been successfully updated',
    errorUpdatePass: 'Could not update password',
    newPass: 'NEW PASSWORD',
  },
  /* Common */
  'Contact us': 'Contact us',
  playNow: 'PLAY NOW',
  buyNFT: 'BUY NFT',
  letsGo: "LET'S GO",
  team: 'Team',
  goLogin: 'Go to Login',
  /* msg Backend */
  'Incorrect email or password': 'Incorrect email or password',
};
