import React from 'react';
import { Link } from 'react-router-dom';
import { Discord } from '../Icons/Discord';
import { Facebook } from '../Icons/Facebook';
import { Instagram } from '../Icons/Instagram';
import { Telegram } from '../Icons/Telegram';
import { LinkButton } from '../Links';
import { SocialMedia, StylesFooterLegends } from './styles';
import { useTranslation } from 'react-i18next';
import { LogoDNAWhite } from '../LogoDNA/logoWhite';

export const FooterLegends = ({ handleWhitePaperAction }) => {
  const { t } = useTranslation();
  const menu = [
    {
      title: 'Whitepaper',
      path: '/whitepaper',
      hash: null,
    },
    {
      title: t('website.menu.howToPlay'),
      path: null,
      hash: 'howToPlay',
    },
    {
      title: 'NFTs',
      path: null,
      hash: 'nfts',
    },
    {
      title: t('website.menu.howToWin'),
      path: null,
      hash: 'howToWin',
    },
    {
      title: t('website.menu.team'),
      path: null,
      hash: 'team',
    },
    {
      title: t('website.menu.contactUs'),
      path: null,
      hash: 'contactus',
    },
  ];
  const handleItem = (hash) => {
    document.getElementById(hash).scrollIntoView({ behavior: 'smooth' });
  };
  const handleWhitePaper = (item) => {
    if (item.title === 'Whitepaper') {
      handleWhitePaperAction();
    }
  };
  return (
    <StylesFooterLegends>
      <div className="row">
        <section className="col-6 col-l-12 footer-info">
          <LogoDNAWhite width="200px" widthImg="80px" opacity />
          <div className="container-links">
            <div className="section-links">
              {menu.slice(0, 3).map((el, idx) => {
                return (
                  <div key={el.title}>
                    {el.hash && (
                      <>
                        <LinkButton
                          onPress={() => handleItem(el.hash)}
                          text={el.title}
                          underline={false}
                          color="var(--gray-text)"
                          fontSize={'20px'}
                          hoverColor={'var(--primary-color)'}
                        />
                      </>
                    )}
                    {el.path && (
                      /*  <Link to={el.path}> */
                      <>
                        <LinkButton
                          text={el.title}
                          underline={false}
                          color="var(--gray-text)"
                          fontSize={'20px'}
                          hoverColor={'var(--primary-color)'}
                          onPress={() => handleWhitePaper(el)}
                        />
                      </>

                      /*  </Link> */
                    )}
                  </div>
                );
              })}
            </div>
            <div className="section-links">
              {menu.slice(3, 6).map((el, idx) => {
                return (
                  <div key={el.title}>
                    {el.hash && (
                      <>
                        <LinkButton
                          onPress={() => handleItem(el.hash)}
                          text={el.title}
                          underline={false}
                          color="var(--gray-text)"
                          fontSize={'20px'}
                          hoverColor={'var(--primary-color)'}
                        />
                      </>
                    )}
                    {el.path && (
                      <Link to={el.path}>
                        <LinkButton
                          text={el.title}
                          underline={false}
                          color="var(--gray-text)"
                          fontSize={'20px'}
                          hoverColor={'var(--primary-color)'}
                        />
                      </Link>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className="col-6 col-l-12 ">
          <div className="item-text mobile">
            <p>{t('footer.followUs')}</p>
          </div>
          <SocialMedia>
            <div className="item-social">
              <a href="https://discord.gg/6DxEPE9DvS" target="_blank" rel="noopener noreferrer">
                <Discord color="#F0F0F0" link />
              </a>
            </div>
            <div className="item-social">
              <a href="https://t.me/dnalegends" target="_blank" rel="noopener noreferrer">
                <Telegram color="#F0F0F0" link />
              </a>
            </div>

            <div className="item-social">
              <a href="https://instagram.com/dnaleg3nds?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                <Instagram color="#F0F0F0" link />
              </a>
            </div>
            <div className="item-social">
              <a href="https://www.facebook.com/DNA-Legends-104342229048356" target="_blank" rel="noopener noreferrer">
                <Facebook color="#F0F0F0" link />
              </a>
            </div>
            <div className="item-text">
              <p>← {t('footer.followUs')}</p>
            </div>
          </SocialMedia>
        </section>
      </div>
      <div className="text-right">
        <p> {t('footer.legends')}</p>
      </div>
    </StylesFooterLegends>
  );
};
