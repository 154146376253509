import Swal from 'sweetalert2';
import PopupContainer from '../assets/img/Cards/popup.png';
import { getSecondMinuteHourBySecond } from './times';
export const ICON = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  QUESTION: 'question',
};

export const AlertDNA = ({
  title = '',
  text = '',
  confirmButtonText = 'OK',
  callback = () => {},
  showConfirmButton = true,
  backdrop = false,
}) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    timer: null,
    timerProgressBar: true,
    showClass: {
      popup: 'animate__animated animate__backInDown',
      backdrop: backdrop ? 'swal2-backdrop-show' : '',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
  });

  Toast.fire({
    title,
    text,
    width: 600,
    padding: '2rem 2rem 1rem',
    background: `var(--bg-dark-home) url(${PopupContainer}) no-repeat center / 100% 100%`,
    confirmButtonText: confirmButtonText,
    showConfirmButton: showConfirmButton,
    customClass: {
      htmlContainer: 'text-alert-dna',
      title: 'title-alert-dna',
      confirmButton: 'confirm-alert-dna',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};
export const AlertDNATwo = ({
  title = '',
  html = '',
  confirmButtonText = 'OK',
  callback = () => {},
  showConfirmButton = true,
  allowOutsideClick = false,
  backdrop = false,
  timeLeft = null,
}) => {
  let timerInterval;
  Swal.fire({
    title,
    html,
    width: 600,
    padding: '2rem 2rem 1rem',
    background: `var(--bg-dark-home) url(${PopupContainer}) no-repeat center / 100% 100%`,
    confirmButtonText: confirmButtonText,
    showConfirmButton: showConfirmButton,
    allowOutsideClick,
    position: 'center',
    timer: timeLeft + 3000,
    timerProgressBar: true,
    showClass: {
      popup: 'animate__animated animate__backInDown',
      backdrop: backdrop ? 'swal2-backdrop-show' : '',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    customClass: {
      htmlContainer: 'text-alert-dna',
      title: 'title-alert-dna',
      confirmButton: 'confirm-alert-dna',
    },
    didOpen: () => {
      const b = Swal.getHtmlContainer().querySelector('b');
      timerInterval = setInterval(() => {
        timeLeft -= 1000;

        b.textContent = timeLeft >= 0 ? getSecondMinuteHourBySecond(timeLeft / 1000) : 'Welcome Legend!';
      }, 1000);
    },
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};
export const AlertForm = ({ title = '', text = '', icon = ICON.INFO, position = 'center', timer = 7000 }) => {
  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: icon === ICON.SUCCESS ? false : true,
    timer,
    timerProgressBar: true,
    showClass: {
      popup: 'animate__animated animate__backInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
  });

  Toast.fire({
    icon,
    title,
    text,
  });
};

export const AlertFormLarge = ({ title = '', text = '', icon = ICON.INFO, footer = null }) => {
  Swal.fire({
    icon,
    title,
    text,
    footer,
  });
};

export const AlertConfirm = ({
  title = '',
  text = '',
  icon = ICON.WARNING,
  callback = () => {},
  textButton = 'Yes',
  showCancelButton = true,
}) => {
  Swal.fire({
    title,
    text,
    icon,
    showCancelButton: showCancelButton,
    confirmButtonColor: 'var(--blue-dark)',
    cancelButtonColor: 'var(--red-color)',
    confirmButtonText: textButton,
    cancelButtonText: 'No',
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const AlertConfirmThree = ({
  title = '',
  html = '',
  callback = () => {},
  fallback = () => {},
  textButton = 'Yes',
  textDeny = 'No',
}) => {
  Swal.fire({
    title,
    html,
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: textButton,
    denyButtonText: textDeny,
    allowOutsideClick: false,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      callback();
    } else if (result.isDenied) {
      fallback();
    }
  });
};

export const AlertHTML = ({ title = '', html = '', icon = ICON.INFO, footer = null }) => {
  Swal.fire({
    icon,
    title,
    html,
    footer,
  });
};

export const AlertConfirmHTML = ({
  title = '',
  html = '',
  icon = ICON.WARNING,
  callback = () => {},
  fallback = () => {},
  textButton = 'Yes',
}) => {
  Swal.fire({
    title,
    html,
    icon,
    showCancelButton: true,
    confirmButtonColor: 'var(--blue-dark)',
    cancelButtonColor: 'var(--red-color)',
    confirmButtonText: textButton,
    cancelButtonText: 'No',
    allowOutsideClick: false,
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    } else if (result.dismiss === 'cancel') {
      fallback();
    }
  });
};

export const AlertLoading = ({ title = 'Guardando...', text = '', allowOutsideClick = false }) => {
  Swal.fire({
    title,
    text,
    allowOutsideClick,
  });
  Swal.showLoading();
};

export const AlertClose = () => {
  Swal.close();
};
