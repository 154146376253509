import { useState, useEffect } from 'react';
import { ViewPort } from '../constants/device';

export const useScreenViewPort = () => {
  const [screenViewPort, setScreenViewPort] = useState(null);

  useEffect(() => {
    const handlerResize = () => {
      let typeScreen = '';
      if (window.innerWidth > 1024) {
        typeScreen = ViewPort.DESKTOP;
      } else if (window.innerWidth > 768) {
        typeScreen = ViewPort.TABLET;
      } else {
        typeScreen = ViewPort.MOBILE;
      }
      setScreenViewPort(typeScreen);
    };
    handlerResize();
    window.addEventListener('resize', handlerResize);
    return () => window.removeEventListener('resize', handlerResize);
  }, []);

  return {
    screenViewPort,
  };
};
