import { createContext, useContext, useEffect, useState } from 'react';
/* import Web3Modal from 'web3modal';
import { ethers } from 'ethers'; */

const NFTMarketplaceContext = createContext();

export const NFTMarketplaceProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState('');
  const checkIfWalletConnected = async () => {
    try {
      if (!window.ethereum) return console.log('install metamask');

      const accounts = await window.ethereum.request({
        method: 'eth_accounts',
      });

      if (accounts.length) {
        setCurrentAccount(accounts[0]);
      } else {
        console.log('No accounts found');
      }

      console.log('account: ', currentAccount);
    } catch (error) {
      console.log('Something wrong while connecting to wallet');
    }
  };
  useEffect(() => {
    checkIfWalletConnected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const connectWallet = async () => {
    try {
      if (!window.ethereum) return console.log('install metamask');
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      setCurrentAccount(accounts[0]);
      window.location.reload();
    } catch (error) {
      console.log('Something wrong while connecting to wallet');
    }
  };
  return (
    <NFTMarketplaceContext.Provider value={{ currentAccount, setCurrentAccount, connectWallet }}>
      {children}
    </NFTMarketplaceContext.Provider>
  );
};

export const useNFTContext = () => useContext(NFTMarketplaceContext);
