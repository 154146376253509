import { Svg } from './styles';

export const Telegram = ({
  color = 'var(--primary-color)',
  hover = 'var(--secondary-color)',
  link,
  onClick = () => {},
}) => {
  return (
    <Svg
      color={color}
      link={link}
      onClick={onClick}
      hover={hover}
      width="36"
      height="30"
      viewBox="0 0 36 30"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.4303 0.197257L1.74402 12.416C-0.418441 13.2846 -0.405931 14.4909 1.34727 15.0289L9.48241 17.5666L28.3047 5.69098C29.1947 5.14947 30.0079 5.44077 29.3395 6.03411L14.0897 19.797H14.0861L14.0897 19.7988L13.5285 28.1841C14.3506 28.1841 14.7134 27.807 15.1745 27.362L19.1259 23.5197L27.345 29.5906C28.8605 30.4252 29.9489 29.9963 30.326 28.1877L35.7214 2.76004C36.2737 0.545753 34.8761 -0.456842 33.4303 0.197257Z"
        fill="#FFFFFF"
      />
    </Svg>
  );
};
