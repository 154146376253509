import React from 'react';
import { GlobalStyle } from './styles/globalStyles';
import { MainProvider } from './context/MainContext';
import { NavigationApp } from './routers';
import { NFTMarketplaceProvider } from './context/ConnectWallet';
import ReactGA from 'react-ga';
const TRACKING_ID = 'UA-252051836-1';

ReactGA.initialize(TRACKING_ID, { debug: true });
ReactGA.pageview(window.location.pathname + window.location.search);

export const App = () => (
  <React.Fragment>
    <GlobalStyle />
    <MainProvider>
      <NFTMarketplaceProvider>
        <NavigationApp />
      </NFTMarketplaceProvider>
    </MainProvider>
  </React.Fragment>
);
