import styled from 'styled-components';
import { device } from '../../constants/device';

export const StylesForm = styled.section`
  & .container-description-form {
    padding: 44px 30px 50px 30px;

    & .text-description {
      font-family: 'Obrazec';
      padding: 2rem;
      transform: rotate(-6deg);
      max-width: 712px;
      margin: auto;
      & p {
        color: var(--blue-color);
        font-size: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        line-height: 0.85;
      }
      & span {
        color: var(--secondary-color);
        font-size: clamp(6.25rem, calc(3.56rem + 5.61vw), 9.38rem);
      }
      &.success {
        height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & p {
          font-size: 50px;
        }
        & span {
          color: var(--secondary-color);
          font-size: 50px;
        }
      }
    }
  }

  & .container-form {
    max-width: ${(props) => (props.type === 'normal' ? '600px' : '480px')};
    margin: 2rem auto 0;
    padding: 2rem 4rem 0;
    border-top: 1px solid #c3c3c3;
    background-color: white;
    color: var(--black-color);
    margin-right: ${(props) => (props.type === 'normal' ? 'auto' : '0')};
  }
  & .text-form-legends {
    font-family: 'Obrazec';
    font-size: 50px;
    color: var(--primary-color);
  }
  & .form-leads {
    padding: 2rem 0;
    & > * {
      margin-bottom: 2rem;
      max-width: ${(props) => (props.type === 'normal' ? '400px' : '100%')};

      &:last-child {
        max-width: 100%;
      }

      &.checkPolicy {
        max-width: 100% !important;
        & label {
          margin-left: 0.6rem;
        }
      }
    }
    & input:not([type='checkbox'], [type='submit']) {
      height: 64px;
      border: 2px solid var(--gray-sec-color);
      border-radius: ${(props) => (props.type === 'normal' ? '10px' : '0')};
      padding: 20px;
      width: 100%;
      font-family: 'Century Gothic', sans-serif;
      font-size: 0.9rem;
      &::placeholder {
        color: var(--gray-sec-color);
      }
      &:focus-visible {
        outline-color: var(--primary-color);
      }
    }
    & button[type='submit'].normal {
      padding: 12.5px 30px;
      border: ${(props) => props.type === 'normal' && '2px solid var(--primary-color)'};
      border-radius: ${(props) => props.type === 'normal' && '50px'};
      font-weight: bold;
      font-size: 20px;
      width: 100%;
      font-family: ${(props) => props.type === 'normal' && 'Century Gothic'};
      background-color: ${(props) => props.type === 'normal' && 'var(--white-color)'};
      color: ${(props) => props.type === 'normal' && 'var(--primary-color)'};
      transition: background-color 0.5s ease, color 0.5s ease;
      &:hover {
        cursor: pointer;
        color: var(--white-color);
        background-color: var(--primary-color);
      }
      &:disabled {
        cursor: not-allowed;
      }
      &:hover:disabled {
        background-color: var(--white-color);
      }
    }
    & button[type='submit'].legendsWS {
      width: 100%;
    }
    & .section-phone {
      display: flex;
      gap: 1rem;
      & .ui.search.dropdown > .text {
        padding-top: 0.8rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
      }
      & .ui.selection.dropdown > .delete.icon,
      .ui.selection.dropdown > .dropdown.icon,
      .ui.selection.dropdown > .search.icon {
        padding: 1.916667em 0.916667em;
      }

      & .ui.dropdown .menu > .item {
        font-size: 0.75rem;
      }

      & .ui.selection.dropdown {
        border: 2px solid var(--gray-sec-color);
        border-radius: ${(props) => (props.type === 'normal' ? '10px' : '0')};
        &.visible {
          border-color: var(--primary-color);
        }
      }

      & .ui.dropdown:not(.button) > .default.text {
        color: var(--gray-sec-color);
      }
    }
  }

  @media ${device.laptop} {
    & .card-form {
      & .container-form {
        margin: 2rem auto 0;
        padding: 4rem 2rem 0;
      }
    }
  }
  @media ${device.tabletL} {
    & .container-form {
      margin: 2rem auto 0;
      padding: 2rem 1rem 0;
    }
    & .form-leads {
      & > * {
        max-width: 100% !important;
      }
    }
  }
  @media ${device.mobileL} {
    & .form-leads {
      & > * {
        &.checkPolicy {
          text-align: center;
        }
      }
      & .section-phone {
        flex-direction: column;
      }
    }
  }
`;
