import React from "react";
import { SectionSocialMedia, StylesNavBar } from "./styles";
import { useLocation } from "react-router-dom";
import { Button } from "../Buttons";
import { LinkButton } from "../Links";

/* import searchImg from '../../assets/img/Search.svg'; */
import { Discord } from "../Icons/Discord";
import { Telegram } from "../Icons/Telegram";
import { Instagram } from "../Icons/Instagram";
import { Facebook } from "../Icons/Facebook";
import { LanguageSelect } from "../Language";
import { useTranslation } from "react-i18next";
import { LogoDNA } from "../LogoDNA";

export const NavBar = ({ handlePlay, handleWhitePaperAction }) => {
  let location = useLocation();
  const { t } = useTranslation();
  const menu = [
    {
      title: "Whitepaper",
      path: "/whitepaper",
      hash: null,
    },
    {
      title: t("website.menu.howToPlay"),
      path: null,
      hash: "howToPlay",
    },
    {
      title: "NFTs",
      path: null,
      hash: "nfts",
    },
    {
      title: t("website.menu.howToWin"),
      path: null,
      hash: "howToWin",
    },
    {
      title: t("website.menu.team"),
      path: null,
      hash: "team",
    },
    {
      title: t("website.menu.contactUs"),
      path: null,
      hash: "contactus",
    },
  ];
  const handleMenu = (e) => {
    let $menu = document.getElementById("hamburger-icon");
    $menu.classList.toggle("open");
    let $menuList = document.getElementById("menuList");
    $menuList.classList.toggle("open");
  };

  const handleItem = (hash) => {
    document.getElementById(hash).scrollIntoView({ behavior: "smooth" });
  };
  const handleWhitePaper = (item) => {
    if (item.title === "Whitepaper") {
      handleWhitePaperAction();
    }
  };
  return (
    <StylesNavBar>
      <div className="header">
        <div className="section-main-header">
          <LogoDNA width="80px" />
          <div className="display-hidden-mobile">
            <LanguageSelect />
          </div>
        </div>
      </div>
    </StylesNavBar>
  );
};
