import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Buttons';
import { StylesNotFound } from './styles';
import Icon404 from '../../assets/img/404/404.svg';
import { LinkButton } from '../../components/Links';
import { SocialMedia } from '../../components/SocialMedia';

export const NotFound = () => {
  return (
    <React.Fragment>
      <StylesNotFound>
        <div>
          <p className="question"> Hey legend where are you?</p>
          <p className="not-found">NOT PAGE FOUND</p>
          <div className="image-404">
            <img src={Icon404} alt="icon 404" />
          </div>
          <div className="back-home">
            <Link to={'/'}>
              <LinkButton text="← Go back to Web site" />
            </Link>
          </div>
          <SocialMedia />
          <p className="footer-text">®DNA Legends 2022. All Right Reserved</p>
        </div>
      </StylesNotFound>
    </React.Fragment>
  );
};
