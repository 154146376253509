import styled from 'styled-components';

export const StylesNotFound = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: var(--fs-largetwo);
  background-color: var(--bg-dark-blue);
  min-height: 100vh;
  text-align: center;
  p {
    &.question {
      font-size: var(--fs-medium);
      margin-bottom: 20px;
    }
    &.not-found {
      font-size: var(--fs-large);
      font-family: 'Obrazec';
      margin-bottom: 20px;
      color: var(--primary-color);
    }
    &.footer-text {
      margin-top: 30px;
      font-size: 15px;
    }
  }
  & .image-404 {
    max-width: 1224px;
    margin: 0 2rem 3rem;
  }
  & .back-home {
    & button {
      margin: auto;
    }
  }
`;
