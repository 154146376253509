import { configureStore } from '@reduxjs/toolkit';

import { todosApi } from './api/todoApi';
import { counterSlice } from './slices/counter';
import { loginSlice } from './slices/login';
import { pokemonsSlice } from './slices/pokeapi';
export const store = configureStore({
  reducer: {
    counter: counterSlice.reducer,
    pokemons: pokemonsSlice.reducer,
    login: loginSlice.reducer,
    [todosApi.reducerPath]: todosApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(todosApi.middleware),
});
