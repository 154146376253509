import React, { useEffect, useState } from 'react';
import { formatDistanceStrict, formatDistanceToNowStrict, isAfter } from 'date-fns';
import { WebSiteLegend } from './Legend';
import { PageCounter } from './PageCounter';
import { useLocation } from 'react-router-dom';

const dateStart = new Date(2022, 10, 14, 19, 0); //14 nov 7pm
const dateEnd = new Date(2022, 10, 21, 18, 25); //21 nov
let intervalTimer = null;
export const WebSite = () => {
  const location = useLocation();
  const [openPageLegends, setOpenPageLegends] = useState(false);
  const [modeDebug, setModeDebug] = useState(false);
  const [timeLeft, setTimeLeft] = useState({ number: '', unit: '', percent: 0 });

  useEffect(() => {
    let timeLeft = formatDistanceToNowStrict(dateEnd, { unit: 'minute' });

    timeLeft = timeLeft.split(' ')[0];
    let leftTwoHours = Number(timeLeft) < 120;
    const resultAfter = isAfter(new Date(), dateEnd);
    setOpenPageLegends(resultAfter);
    if (leftTwoHours) {
      intervalTimer = setInterval(() => {
        updateStatus(timeLeft);
        const resultAfter = isAfter(new Date(), dateEnd);
        setOpenPageLegends(resultAfter);
      }, 1000);
    } else {
      updateStatus(timeLeft);
    }

    setModeDebug(location.search.includes('debug=true'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (openPageLegends) {
      if (intervalTimer) clearInterval(intervalTimer);
    }
  }, [openPageLegends]);
  const updateStatus = (timeLeft) => {
    let result = formatDistanceToNowStrict(dateEnd);
    result = result.split(' ');

    let resultDays = formatDistanceToNowStrict(dateEnd, {
      unit: 'hour',
    });
    resultDays = resultDays.split(' ');
    let resultDistance = formatDistanceStrict(dateStart, dateEnd, {
      unit: 'hour',
    });
    resultDistance = Number(resultDistance.split(' ')[0]);
    let percentPerDay = 100 / resultDistance;
    let daysNow = resultDistance - Number(resultDays[0]);
    let percentTotal1 = parseInt(daysNow * percentPerDay);
    setTimeLeft({ number: result[0], unit: result[1], percent: percentTotal1 });
  };
  return (
    <>
      {openPageLegends && <WebSiteLegend modeDebug={modeDebug} />}
      {!openPageLegends && (
        <PageCounter
          dateStart={dateStart}
          dateEnd={dateEnd}
          modeDebug={modeDebug}
          setOpenPageLegends={setOpenPageLegends}
          timeLeft={timeLeft}
        />
      )}
    </>
  );
};
