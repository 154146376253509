import { createContext, useContext, useEffect, useState } from 'react';

const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const [dataMain, setDataMain] = useState({
    languagePage: null,
  });
  useEffect(() => {
    let language = /*  navigator.language || navigator.userLanguage || */ 'en';
    setDataMain({ ...dataMain, languagePage: language });
  }, []);

  return <MainContext.Provider value={{ dataMain, setDataMain }}>{children}</MainContext.Provider>;
};

export const useMainContext = () => useContext(MainContext);
