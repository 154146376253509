import { authentication, autologin, failed, startLogin } from '.';
import { ApiRequest } from '../../../utils/ApiRequest';

export const authenticationUser = ({ email, password, remember }) => {
  return async (dispatch, getState) => {
    dispatch(startLogin());
    try {
      // TODO: Realizar petición HTTP del login
      let res = await ApiRequest.publicPost('/get-token', {
        user: email,
        password: password,
      });

      let token = res.token;

      if (remember) {
        localStorage.setItem('user', token);
        localStorage.setItem('email', email);
      } else {
        sessionStorage.setItem('user', token);
        sessionStorage.setItem('email', email);
      }

      dispatch(
        authentication({
          userLogin: token,
          name: res.user.user,
          email: email,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        failed({
          message: err.status === 'Data Failure' ? 'Correo y/o contraseña NO Valido' : err.statusText,
        })
      );
    }
  };
};
export const autoLoginUser = (user) => {
  return async (dispatch, getState) => {
    dispatch(startLogin());

    // TODO: Realizar petición HTTP de auto login, validando el jwt
    let user = localStorage.getItem('user') || sessionStorage.getItem('user');
    let email = localStorage.getItem('email') || sessionStorage.getItem('email');

    if (user) {
      dispatch(
        autologin({
          userLogin: user,
          email: email,
        })
      );
    }
  };
};
