import styled from 'styled-components';

export const StylesLink = styled.section`
  border: none;
  background-color: transparent;
  font-family: 'Century Gothic', sans-serif;
  color: ${(props) => props.color || 'white'};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  font-size: ${(props) => props.fontSize || 'var(--fs-medium)'};
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.7s ease-in-out, transform 0.5s ease-in-out;
  & + p {
    color: ${(props) => props.color || 'white'};
    transition: color 0.7s ease-in-out;
  }
  &:hover,
  &:hover + p {
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color || 'white')};
    transform: ${(props) => (props.cursive ? 'skewX(-12deg) scale(1.1)' : 'skewX(0deg)')};
  }
  & img {
    width: ${(props) => (props.widthIcon ? props.widthIcon : '25px')};
    margin-right: 0.5rem;
  }
`;

export const StylesAnchor = styled.a`
  border: none;
  background-color: transparent;
  font-family: 'Century Gothic', sans-serif;
  color: ${(props) => props.color || 'white'};
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  font-size: ${(props) => props.fontSize || 'var(--fs-medium)'};
  cursor: pointer;
  transition: color 0.7s ease-in-out;
  &:hover,
  & + p {
    color: ${(props) => (props.hoverColor ? props.hoverColor : props.color || 'white')};
  }
`;
