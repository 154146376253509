import React, { useEffect, useState } from 'react';
import { formatDistanceStrict, formatDistanceToNowStrict, isAfter } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons';
import { Container } from '../../../styles/globalStyles';
import { StylesPageCounter } from './styles';
import { SocialMedia } from '../../../components/SocialMedia';
import LogoFill from '../../../assets/img/Counter/Fill.png';
import LogoLine from '../../../assets/img/Counter/Line.png';
import { LinkButton } from '../../../components/Links';

export const PageCounter = ({ dateStart, dateEnd, modeDebug, setOpenPageLegends, timeLeft }) => {
  let navigate = useNavigate();

  const [active, setActive] = useState(false);

  useEffect(() => {
    const resultAfter = isAfter(new Date(), dateEnd);
    if (!resultAfter) setActive(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateStart, dateEnd]);
  const goPageLengends = () => {
    setOpenPageLegends(true);
  };
  return (
    <React.Fragment>
      <StylesPageCounter percent={timeLeft.percent}>
        {active && (
          <>
            <div className="text-up">
              <span className="text-time">
                {timeLeft.number} {timeLeft.unit} left
              </span>
              {modeDebug && <LinkButton text="Go to Legends" onPress={goPageLengends} fontSize="1.4rem" />}
            </div>
            <div className="logo-loading">
              <img src={LogoLine} alt="Logo Loading" />
              <div className="abs-img">
                <img src={LogoFill} alt="Logo Loading" />
              </div>
            </div>
            <div className="percent">
              <p>{timeLeft.percent}%</p>
            </div>
            <div className="description">
              <p>
                Hi! we are launching the most <b>legendary</b> website.
              </p>
              <p>Stay tune.</p>
            </div>
            <SocialMedia />
            <div className="footer-text">
              <p>®DNA Legends 2022. All Right Reserved</p>
            </div>
          </>
        )}
      </StylesPageCounter>
    </React.Fragment>
  );
};
