import styled, { createGlobalStyle } from 'styled-components';

import PoppinsBold from '../assets/fonts/Poppins/Poppins-Bold.ttf';
import PoppinsBoldItalic from '../assets/fonts/Poppins/Poppins-BoldItalic.ttf';
import PoppinsItalic from '../assets/fonts/Poppins/Poppins-Italic.ttf';
import PoppinsLight from '../assets/fonts/Poppins/Poppins-Light.ttf';
import PoppinsRegular from '../assets/fonts/Poppins/Poppins-Regular.ttf';
import PressStart from '../assets/fonts/Press_Start_2P/PressStart2P-Regular.ttf';
import Obrazec from '../assets/fonts/obrazec/obrazec.otf';
import Gothic from '../assets/fonts/century_gothic/GOTHIC.TTF';
import GothicBold from '../assets/fonts/century_gothic/GOTHICB.TTF';
import GothicBoldItalic from '../assets/fonts/century_gothic/GOTHICBI.TTF';
import GothicItalic from '../assets/fonts/century_gothic/GOTHICI.TTF';

import { device } from '../constants/device';

export const GlobalStyle = createGlobalStyle`
  /* CUSTOM PROPERTIES - VARIABLES
  ============================= */
  :root {
    --primary-color:#00CBC4;
    --secondary-color: #FF408E;
    --primary-color-medium:#00CBC480;
    --secondary-color-medium: #FF408E80;
    --black-color: #010101;
    --bg-loading: #1E1E1E;
    --gray-text: #C3C3C3;
    --gray-dark-text: #777777;
    --gray-sec-color: #A3A3A3;
    --line-horizontal: #A3A3A3;
    --white-color: #ffffff;
    --blue-color: #007D81;
    --blue-secondary-color: #2A7485;
    --bg-loading: #1E1E1E;
    --green-dark: #00817D;
    --bg-dark-home: #1F2833;
    --yellow-color: #FFE352;
    --bg-dark-blue: #040C21;


/* font size responsive 390px - 1240px*/

      
    --fs-small: clamp(0.75rem, calc(0.66rem + 0.35vw), 0.94rem);; // 15px - 12px
    --fs-medium-three: clamp(0.75rem, calc(0.52rem + 0.94vw), 1.25rem); // 20px - 12px
    --fs-btn: clamp(1rem, calc(0.70rem + 0.71vw), 1.25rem); // 20px - 16px
    --fs-normal: var(--fs-btn);
    --fs-medium: clamp(1.25rem, calc(0.96rem + 1.18vw), 1.88rem); //30px - 20px
    --fs-medium-two: clamp(1.00rem, calc(0.60rem + 1.65vw), 1.88rem); // 30px - 16 px
    --fs-menu: clamp(2.50rem, calc(1.93rem + 2.35vw), 3.75rem); // 40px - 60px
    --fs-medium-large-two: clamp(1.88rem, calc(1.30rem + 2.35vw), 3.13rem);//50px - 30px
    --fs-medium-large: clamp(2.50rem, calc(1.84rem + 2.71vw), 3.94rem);//63px - 40px

    --fs-large: clamp(3.13rem, calc(1.69rem + 5.88vw), 6.25rem); //100px - 50px
    --fs-large-four: clamp(4.38rem, calc(4.06rem + 1.29vw), 5.06rem); //81px - 70px
    --fs-large-three: clamp(4.38rem, calc(3.51rem + 3.53vw), 6.25rem); // 100px - 70px
    --fs-large-two: clamp(7.50rem, calc(5.49rem + 8.24vw), 11.88rem); // 190px - 120px

    --fs-carrousel: clamp(1.25rem, calc(0.68rem + 2.35vw), 2.50rem); // 40px - 20px
    --fs-active-carrousel: clamp(1.25rem, calc(0.39rem + 3.53vw), 3.13rem); // 50px - 20px
    /* Image card responsive */
    --img-logo: clamp(6.25rem, calc(2.12rem + 16.94vw), 15.25rem); // 244px - 100px
    --img-hero: clamp(33.75rem, calc(21.88rem + 48.71vw), 59.63rem); // 954px - 540px
  }

  /* FONTS
  ============================= */
  /* Poppins */
  @font-face {
    font-family: 'Poppins Bold';
    src: local('Poppins Bold'), local('Poppins Bold'),
    url(${PoppinsBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Poppins Bold Italic';
    src: local('Poppins Bold Italic'), local('Poppins Bold Italic'),
    url(${PoppinsBoldItalic}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'Poppins Italic';
    src: local('Poppins Italic'), local('Poppins Italic'),
    url(${PoppinsItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Poppins Light';
    src: local('Poppins Light'), local('Poppins Light'),
    url(${PoppinsLight}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Poppins Regular';
    src: local('Poppins Regular'), local('Poppins Regular'),
    url(${PoppinsRegular}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  /* Obrazec */
  @font-face {
    font-family: 'Obrazec';
    src: local('Obrazec'), local('Obrazec'),
    url(${Obrazec}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  /* PressStart */
  @font-face {
    font-family: 'Press Start 2P';
    src: local('Press Start 2P'), local('Press Start 2P'),
    url(${PressStart}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  /* Century Gothic */
  @font-face {
    font-family: 'Century Gothic';
    src: local('Century Gothic'), local('Century Gothic'),
    url(${Gothic}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Century Gothic Bold';
    src: local('Century Gothic Bold'), local('Century Gothic Bold'),
    url(${GothicBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Century Gothic Bold Italic';
    src: local('Century Gothic Bold Italic'), local('Century Gothic Bold Italic'),
    url(${GothicBoldItalic}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'Century Gothic Italic';
    src: local('Century Gothic Italic'), local('Century Gothic Italic'),
    url(${GothicItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }


/* GLOBALS
  ============================= */
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-size: 16px;
    overflow-x: hidden;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  body {
    font-family: 'Century Gothic', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    
  }

  figure {
    margin: 0;
  }
  img, video {
    width: 100%;
    height: auto;
  }

  p {
    margin: 0;
    line-height: 1;
  }
  
  // Style SCROLL - No aplica firefox
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 20px 20px var(--bg-dark-home);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 13px 6px var(--primary-color);
  }
  /* Alertas */
  .title-alert-dna {
    font-family: 'Press Start 2P';
    font-size: 22px !important;
    color: var(--primary-color);
    padding: 0;
    text-align: center !important;
  }
  .text-alert-dna {
    font-family: 'Century Gothic';
    font-size: 18px !important;
    color: var(--primary-color);
    text-align: center !important;
  }
  .swal2-styled.confirm-alert-dna {
    background: transparent;
    font-family: 'Press Start 2P';
    font-size: 15px !important;
    color: var(--primary-color);
    width: 100%;
    &:focus {
      box-shadow: none;
    }
  }
  .swal2-backdrop-show {
    width: 100% !important;
    background-color: #0000004a !important;
    backdrop-filter: blur(16px);
    & > div {
      max-width: 600px !important;
    }
  }

  @media ${device.tabletL} {
    .title-alert-dna {
      font-size: 20px;
    }
    .text-alert-dna {
      font-size: 16px;
    }
    .swal2-styled.confirm-alert-dna {
      font-size: 14px;
    }
  }
  @media ${device.mobileL} {
    .title-alert-dna {
      font-size: 18px;
    }
    .text-alert-dna {
      font-size: 14px;
    }
    .swal2-styled.confirm-alert-dna {
      font-size: 13px;
    }

    .swal2-backdrop-show {
      & > div {
        width: calc(100vw - 20px) !important;
      }
    }
  }
  /* Responsive */

  .row {
    display: flex;
    flex-wrap: wrap;
  }
  .align-center {
    align-items: center;
  }
  .col, .col-12 {
    flex-basis: 100%;
  }
  .col-10 {
    flex-basis: 83.6%;
  }
  .col-8 {
    flex-basis: 66.6%;
  }
  .col-7 {
    flex-basis: 58.33%;
  }
  .col-6 {
    flex-basis: 50%;
  }
  .col-5 {
    flex-basis: 41.66%;
  }
  .col-4 {
    flex-basis: 33.3%;
  }
  .col-3 {
    flex-basis: 25%;
  }
  .col-2 {
    flex-basis: 16.66%;
  }
  .col-1 {
    flex-basis: 16.66%;
  }

  @media ${device.laptop} {
    .col-xl-12 {
      flex-basis: 100%;
    }
    .col-xl-8 {
      flex-basis: 66.6%;
    }
    .col-xl-6 {
      flex-basis: 50%;
    }
    .col-xl-4 {
      flex-basis: 33.3%;
    }
    .col-xl-3 {
      flex-basis: 25%;
    }
    .col-xl-2 {
      flex-basis: 16.66%;
    }
    .col-xl-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.tabletL} {
    .col-l-12 {
      flex-basis: 100%;
    }
    .col-l-8 {
      flex-basis: 66.6%;
    }
    .col-l-6 {
      flex-basis: 50%;
    }
    .col-l-4 {
      flex-basis: 33.3%;
    }
    .col-l-3 {
      flex-basis: 25%;
    }
    .col-l-2 {
      flex-basis: 16.66%;
    }
    .col-l-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.tablet} {
    .col-m-12 {
      flex-basis: 100%;
    }
    .col-m-8 {
      flex-basis: 66.6%;
    }
    .col-m-6 {
      flex-basis: 50%;
    }
    .col-m-4 {
      flex-basis: 33.3%;
    }
    .col-m-3 {
      flex-basis: 25%;
    }
    .col-m-2 {
      flex-basis: 16.66%;
    }
    .col-m-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.mobileL} {
    .col-s-12 {
      flex-basis: 100%;
    }
    .col-s-8 {
      flex-basis: 66.6%;
    }
    .col-s-6 {
      flex-basis: 50%;
    }
    .col-s-4 {
      flex-basis: 33.3%;
    }
    .col-s-3 {
      flex-basis: 25%;
    }
    .col-s-2 {
      flex-basis: 16.66%;
    }
    .col-s-1 {
      flex-basis: 16.66%;
    }
  }
  @media ${device.mobileM} {
    .col-xs-12 {
      flex-basis: 100%;
    }
    .col-xs-8 {
      flex-basis: 66.6%;
    }
    .col-xs-6 {
      flex-basis: 50%;
    }
    .col-xs-4 {
      flex-basis: 33.3%;
    }
    .col-xs-3 {
      flex-basis: 25%;
    }
    .col-xs-2 {
      flex-basis: 16.66%;
    }
    .col-xs-1 {
      flex-basis: 16.66%;
    }
  }
`;

export const PageWrapper = styled.div`
  min-height: calc(100vh - 601px);
`;
export const Container = styled.div`
  max-width: 1480px;
  margin: 0 auto;
  position: relative;
  @media ${device.desktop} {
    max-width: 1400px;
  }
  @media ${device.laptop} {
    max-width: 1250px;
  }
`;
