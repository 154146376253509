import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './locale/i18n';
import 'animate.css';
import { store } from './store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
