import React, { useEffect, useState } from 'react';
import { StylesLogo } from './styles';
import LogoDNALegends from '../../assets/img/LogoDNA.svg';
import TextDNA from '../../assets/img/TextDNA.svg';

export const LogoDNA = ({ width, widthImg }) => {
  const [imgWidth, setImgWidth] = useState('0px');
  useEffect(() => {
    if (!widthImg && width) {
      let matches = width.match(/\d+/g);
      setImgWidth(`${Number(matches[0]) * 0.75}px`);
    } else if (widthImg && width) {
      setImgWidth(widthImg);
    }
  }, [widthImg, width]);

  return (
    <StylesLogo width={width} widthImg={imgWidth}>
      <div className="image-section">
        <div className="section-logo">
          <div className="logo-dna">
            <img src={LogoDNALegends} alt="logo" />
          </div>
          <div className="text-dna">
            <img src={TextDNA} alt="textDNA" />
          </div>
        </div>
      </div>
    </StylesLogo>
  );
};
