export const getSecondMinuteHourBySecond = (seconds) => {
  let second = seconds % 60;
  let minutes = (seconds / 60) % 60;
  let hour = Math.floor(seconds / 60 / 60) % 24;
  let day = Math.floor(seconds / 60 / 60 / 24);

  return `${parseInt(day)} day ${parseInt(hour).toString().padStart(2, '0')} hours ${parseInt(minutes)
    .toString()
    .padStart(2, '0')} minutes ${parseInt(second).toString().padStart(2, '0')} seconds`;
};

export const getHourByTime = (time) => {
  return new Date(Number(time)).toLocaleTimeString('es', { hour: '2-digit', minute: '2-digit' });
};

export const getDateByTime = (time) => {
  let date = new Date(Number(time)).toLocaleDateString('es');
  if (date === new Date().toLocaleDateString('es')) date = 'Hoy';
  return date;
};
