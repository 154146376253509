import styled from 'styled-components';

export const StylesLoading = styled.section`
  background-color: #0f141a;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  width: 130px;
  & img {
    opacity: 0;
    transform: translateY(1000px);
    &.transition {
      opacity: 1;
      transform: translateY(0);
      transition: opacity 1s ease, transform 1s cubic-bezier(0.28, 0.73, 0.61, 1.34);
    }
  }
`;
