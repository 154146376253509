import React, { Fragment, useEffect, useState } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LoadingStartPage } from "../components/Loadings/LoadingStartPage";

import { PageWrapper } from "../styles/globalStyles";
import { NotFound } from "../views/Not Found";
import { StylesPages } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { autoLoginUser } from "../store/slices/login/thunks";
import { WebSite } from "../views/WebSite/index";

const AuthenticatedUser = ({ children }) => {
  return (
    <Fragment>
      <PageWrapper>{children}</PageWrapper>
    </Fragment>
  );
};

const NotAuthenticatedUser = ({ children }) => {
  return (
    <Fragment>
      <StylesPages>
        <PageWrapper>{children}</PageWrapper>
      </StylesPages>
    </Fragment>
  );
};

export const NavigationApp = () => {
  const dispatch = useDispatch();
  const { userLogin, loadGame } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 3000);
    dispatch(autoLoginUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingStartPage />;
  }
  return (
    <Fragment>
      <Router>
        {/* Rutas Publicas que puede acceder cualquier usuario */}
        {(!userLogin || !loadGame) && (
          <NotAuthenticatedUser>
            <Routes>
              <Route path="/" element={<WebSite />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </NotAuthenticatedUser>
        )}
        {/* Rutas si hay un login y usuario esta autenticado */}
        {userLogin && loadGame && (
          <AuthenticatedUser>
            <Routes>
              <Route path="/" element={<WebSite />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthenticatedUser>
        )}
      </Router>
    </Fragment>
  );
};
