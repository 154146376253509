import styled from 'styled-components';
import { device } from '../../constants/device';

export const StylesNavBar = styled.section`
  font-family: 'Century Gothic', sans-serif;
  color: white;
  background: rgb(0 0 0 / 30%);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  & > .header {
    padding: 8px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(10px);
    transition: background 1s ease;
  }
  & .section-main-header {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  & nav {
    display: flex;
    align-items: center;
    gap: 70px;
    font-size: 20px;
    & > .active {
      position: relative;

      font-size: 30px;
      &::after {
        position: absolute;
        content: '';
        top: 50px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: white;
      }
      & button {
        font-family: 'Century Gothic Bold', sans-serif;
      }
    }
  }
  & .menu-hamburguer {
    & .hamburger-icon {
      width: 50px;
      height: 50px;
      cursor: pointer;
      & .menu-btn .line {
        padding: 0;
        width: 30px;
        background: var(--primary-color);
        height: 5px;
        margin: 9px 0;
        transition: all 500ms cubic-bezier(0.18, 0.28, 0.58, 1);
      }
      & .menu-btn .line.line--1 {
        width: 45px;
        transform: rotate(0) translateY(0);
      }
      & .menu-btn .line.line--2 {
        width: 45px;
        transform: translateX(0);
        margin-left: auto;
      }
      & .menu-btn .line.line--3 {
        width: 45px;
        transform: rotate(0) translateY(0);
        margin-left: auto;
      }
      &:hover .menu-btn .line.line--1 {
        width: 45px;
        transform: rotate(90deg) translate(15px, 0px);
      }
      &:hover .menu-btn .line.line--2 {
        width: 28px;
        transform: rotate(230deg) translate(3px, -21px);
      }
      &:hover .menu-btn .line.line--3 {
        width: 28px;
        transform: rotate(-48deg) translate(0px, -1px);
      }

      &.open {
        & .menu-btn .line.line--1 {
          width: 50px;
          transform: rotate(45deg) translateY(18px);
        }
        & .menu-btn .line.line--2 {
          width: 32px;
          transform: scale(0);
        }
        & .menu-btn .line.line--3 {
          width: 50px;
          transform: rotate(-45deg) translateY(-20px);
        }
      }
    }
  }

  & .menuList {
    position: absolute;
    top: 96px;
    left: 0;
    background: #1b232d;

    width: 100vw;
    height: calc(100vh - 96px);
    overflow: auto;
    text-align: center;
    transform: translateY(-50vh) translateX(-50vw) scale(0);
    border-radius: 0 0 93% 0;
    opacity: 0;
    transition: transform 0.6s linear, opacity 0.6s linear, border-radius 0.8s ease-in-out;
    &.open {
      display: block;
      transform: translateY(0px) translateX(0px) scale(1);
      opacity: 1;
      border-radius: 0%;
      padding: 2rem 8rem;
      & + .header {
        background: #1b232d;
      }
    }
    & .menu-items {
      text-align: end;
      & .menu-item {
        position: relative;
        padding-top: 1.2rem;
      }
      & button {
        font-family: 'Century Gothic Bold';
        margin-left: auto;
      }
      & .count-menu {
        font-family: 'Press Start 2P';
        position: absolute;
        bottom: -20px;
        right: -20px;
        font-size: 15px;
        line-height: 15px;
      }
    }
    & .bg-search {
      width: 100%;
      height: 80%;
    }
    & .input-custom {
      width: 80%;
      margin: 2rem 0;
      position: relative;
      & input {
        height: 48px;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid var(--primary-color);
        width: 100%;
        font-size: var(--fs-medium-two);
        color: var(--primary-color);
        padding-left: 3rem;
        &::placeholder {
          color: var(--primary-color);
          opacity: 0.75;
        }
        &:focus-visible {
          border: none;
          border-bottom: 2px solid var(--secondary-color);
          outline: none;
        }
      }
      & img {
        position: absolute;
        width: 30px;
        top: 8px;
        left: 10px;
      }
    }
  }
  & .display-hidden-mobile {
    display: block;
  }
  & .display-show-mobile {
    display: none;
  }
  & .searchLogo {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  @media ${device.laptop} {
    & > div:first-child {
      padding: 20px;
    }
    & nav {
      gap: 20px;
    }
  }
  @media ${device.tabletL} {
    & > .header {
      padding: 20px 50px;
    }
    & .menuList {
      &.open {
        padding: 2rem 5rem;
      }
    }
  }
  @media ${device.tablet} {
    & .menu-laptop,
    & .btn-legends {
      display: none;
    }
    & .display-hidden-mobile {
      display: none;
    }
    & .display-show-mobile {
      display: block;
      &.language {
        max-width: 150px;
      }
    }
    & .section-main-header {
      justify-content: space-between;
      flex-basis: 100%;
    }

    @media ${device.mobileL} {
      & > .header {
        padding: 20px;
      }
      & .menuList {
        &.open {
          padding: 2rem;
        }
      }
    }
  }
`;

export const SectionSocialMedia = styled.section`
  & .social-section {
    display: flex;
    margin-top: 2rem;
    gap: 6rem;
    & .item-social {
      width: 30px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  @media ${device.tabletL} {
    & .social-section {
      margin-top: 4rem;
      gap: 3rem;
    }
  }
`;
