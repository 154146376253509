import styled from 'styled-components';

export const StyleButton = styled.button`
  background-color: ${(props) => props.bgColor || 'transparent'};
  font-family: 'Press Start 2P', sans-serif;
  color: ${(props) => props.color || 'var(--green-dark)'};
  border: 5px solid white;
  border-color: ${(props) => props.color || 'var(--green-dark)'};
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
  cursor: pointer;
  padding: 10px 16px;
  text-align: center;
  font-size: ${(props) => props.fontSize || 'var(--fs-btn)'};
  font-weight: bold;
  outline: none;
  position: relative;
  display: inline-block;
  min-width: 150px;
  width: ${(props) => (props.widthFull ? '100%' : null)};
  transition: background-color 0.5s linear, color 0.5s linear, border 0.5s linear, transform 0.5s linear,
    border 0.5s linear;
  &:hover {
    background-color: ${(props) => props.bgColorHover || '#38f2e4'};
    transform: scale(1.03);
  }
`;
