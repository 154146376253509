import { Svg } from './styles';

export const Facebook = ({
  color = 'var(--primary-color)',
  hover = 'var(--secondary-color)',
  link,
  onClick = () => {},
}) => {
  return (
    <Svg
      color={color}
      link={link}
      onClick={onClick}
      hover={hover}
      width="18"
      height="30"
      viewBox="0 0 18 30"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.47798 30H10.7938V17.3526H16.4843L17.1096 11.0684H10.7938V7.89474C10.7938 7.47597 10.9601 7.07436 11.2562 6.77825C11.5523 6.48214 11.954 6.31579 12.3727 6.31579H17.1096V0H12.3727C10.2789 0 8.27085 0.831765 6.7903 2.31231C5.30975 3.79287 4.47798 5.80092 4.47798 7.89474V11.0684H1.32009L0.694824 17.3526H4.47798V30Z"
        fill="white"
      />
    </Svg>
  );
};
