import { Discord } from '../Icons/Discord';
import { Facebook } from '../Icons/Facebook';
import { Instagram } from '../Icons/Instagram';
import { Telegram } from '../Icons/Telegram';
import { SocialMediaStyles } from './styles';

export const SocialMedia = () => (
  <SocialMediaStyles>
    <div className="item-social">
      <a href="https://discord.gg/6DxEPE9DvS" target="_blank" rel="noopener noreferrer">
        <Discord color="#F0F0F0" link />
      </a>
    </div>
    <div className="item-social">
      <a href="https://t.me/dnalegends" target="_blank" rel="noopener noreferrer">
        <Telegram color="#F0F0F0" link />
      </a>
    </div>

    <div className="item-social">
      <a href="https://instagram.com/dnaleg3nds?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
        <Instagram color="#F0F0F0" link />
      </a>
    </div>
    <div className="item-social">
      <a href="https://www.facebook.com/DNA-Legends-104342229048356" target="_blank" rel="noopener noreferrer">
        <Facebook color="#F0F0F0" link />
      </a>
    </div>
  </SocialMediaStyles>
);
