import styled from 'styled-components';
import { device } from '../../../constants/device';

export const StylesPageCounter = styled.section`
  color: white;
  font-size: var(--fs-largetwo);
  background-color: var(--bg-dark-blue);
  min-height: 100vh;
  padding: 50px 100px;
  text-align: center;
  & .text-up {
    text-align: end;
    font-family: 'Obrazec';
    font-size: var(--fs-medium-large-two);
    color: var(--secondary-color);
    margin-bottom: 2rem;
  }
  & .logo-loading {
    width: 300px;
    margin: 3rem auto 2rem;
    position: relative;
    & .abs-img {
      position: absolute;
      top: 0;
      left: 0;
      clip-path: inset(${(props) => (props.percent ? 300 - props.percent * 3 + 'px' : '300px')} 0px 0px 0px);
    }
  }
  & .percent {
    font-family: 'Press Start 2P';
    font-size: var(--fs-large-three);
    color: var(--primary-color);
  }
  & .description {
    margin-top: 5rem;
    font-size: var(--fs-medium);
  }
  & .footer-text {
    font-size: 15px;
    margin-top: 3rem;
  }
  @media ${device.laptop} {
    padding: 20px 100px 0;
    & .text-up {
      font-size: var(--fs-carrousel);
      margin-bottom: 1rem;
    }
    & .logo-loading {
      width: 250px;
      margin: 1rem auto 1.5rem;
      & .abs-img {
        clip-path: inset(${(props) => (props.percent ? 250 - props.percent * 2.5 + 'px' : '250px')} 0px 0px 0px);
      }
    }
    & .percent {
      font-size: 50px;
    }
    & .description {
      font-size: var(--fs-normal);
      max-width: 700px;
      margin: 1rem auto 0;
      & p:first-child {
        margin-bottom: 1rem;
      }
    }
    & .footer-text {
      font-size: 15px;
      margin-top: 2rem;
    }
  }
  @media ${device.tablet} {
    padding: 20px;
  }
  @media ${device.mobileL} {
    & .logo-loading {
      width: 200px;
      & .abs-img {
        clip-path: inset(${(props) => (props.percent ? 200 - props.percent * 2 + 'px' : '200px')} 0px 0px 0px);
      }
    }
  }
`;
