import styled from 'styled-components';
import CardNft from '../../assets/img/Cards/Card-NFT.png';
import { device } from '../../constants/device';
import CardHover from '../../assets/img/Cards/Card Hover.png';
//import { device } from '../../constants/device';

export const GeneralCarrousel = styled.section`
  .swiper-button-next,
  .swiper-button-prev {
    color: var(--primary-color);
    &::after {
      content: '>';
      font-family: 'Press Start 2P';
    }
  }
  .swiper-button-prev {
    &::after {
      content: '<';
    }
  }
  .swiper-pagination-bullet {
    border-radius: 0;
    width: 10px;
    height: 10px;
    background-color: #6f98c8;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--primary-color);
    width: 15px;
    height: 15px;
  }
`;
export const StylesCardCarrousel = styled.section`
  backdrop-filter: blur(7.5px);
  text-align: center;
  position: relative;
  margin: 1rem 0 3rem;
  & > div {
    height: 100%;
    width: 100%;
  }

  & .card-main {
    margin: 3rem 1rem;
    min-height: 500px;
    position: relative;
    text-align: center;
    color: var(--primary-color);
    flex-direction: column;
    justify-content: space-between;
    z-index: 9;
    backdrop-filter: blur(7.5px);
    transition: transform 0.5s ease;
    background-color: rgba(31, 40, 51, 0.6);

    &:hover {
      background: url('${CardHover}') no-repeat;
      background-size: 100% 100%;
      opacity: 1;
      & .title-card::before {
        content: '◇';
        margin-right: 1rem;
      }
    }
    & .section-header,
    & .links {
      padding: 3rem;
      margin: auto;
    }
    & .title-card {
      font-family: 'Press Start 2P';
      font-size: var(--fs-medium);
    }
    & .description-card {
      font-size: var(--fs-normal);
      padding-top: 2.5rem;
    }
    & button {
      margin-bottom: 1rem;
    }
  }
  @media ${device.tablet} {
    & .card-main {
      min-height: 350px;
    }
  }
  @media ${device.mobileL} {
    & .card-main {
      padding: 0rem;
      margin: 0rem;
      min-height: 300px;
    }
  }
`;
