import React, { useState } from 'react';
import { ImageContainer, StylesLoading } from './styles';
import Logo from '../../assets/img/Logo DNA Legends.png';
export const LoadingStartPage = () => {
  const [transition, setTransition] = useState('');
  setTimeout(() => {
    setTransition('transition');
  }, 500);
  return (
    <StylesLoading>
      <ImageContainer>
        <img className={transition} src={Logo} alt="loading-logo" />
      </ImageContainer>
    </StylesLoading>
  );
};
