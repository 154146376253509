import styled from 'styled-components';
import { device } from '../../constants/device';

export const SocialMediaStyles = styled.section`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 3rem;
  z-index: 4;
  @media ${device.tablet} {
    justify-content: center;
  }
`;
