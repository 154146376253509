import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Buttons";
import { Discord } from "../../../components/Icons/Discord";
import { Facebook } from "../../../components/Icons/Facebook";
import { Instagram } from "../../../components/Icons/Instagram";
import { Telegram } from "../../../components/Icons/Telegram";
import { NavBar } from "../../../components/NavBar";
import { Container } from "../../../styles/globalStyles";
import {
  CardToPlay,
  DashboardToken,
  FormSection,
  GameForAll,
  HeroStyles,
  HowToPlay,
  ItemGraphic,
  NFT,
  Partners,
  PlayNowStyles,
  SocialMedia,
  StylesHome,
  TeamSection,
} from "./styles";
import Mutante from "../../../assets/img/mutants/Toro Ilustration.png";
import ArrowOne from "../../../assets/img/Arrow 1.svg";
import ArrowTwo from "../../../assets/img/Arrow 2.svg";
import Toro from "../../../assets/img/mutants/Toro Ilustration2.png";
import Rino from "../../../assets/img/mutants/Rino Ilustration.png";
import Arrows from "../../../assets/img/Arrows.png";
import infoIcon from "../../../assets/img/info.svg";
import Livan from "../../../assets/img/partners/Livan.svg";
import DNAcademy from "../../../assets/img/partners/DNAcademy.svg";
import EF from "../../../assets/img/partners/EF.svg";
import TokenPartner from "../../../assets/img/partners/Token Partner.svg";
import { FormLead } from "../../../components/FormLead";
import { FooterLegends } from "../../../components/Footer/FooterLegends";
import { Carrousel } from "../../../components/Carrousel";
import { useTranslation } from "react-i18next";
/* import { Doughnut } from '../../../components/Chart/Doughnut'; */
import Graphic from "../../../assets/img/graphic.svg";
import { AlertDNA, AlertDNATwo } from "../../../utils/Alert";
import { CarrouselNFT } from "../../../components/CarrouselNFT";
import ToroFront from "../../../assets/img/SpritesToro/Caminando frontal.gif";
import ToroBack from "../../../assets/img/SpritesToro/Caminando espaldas .gif";
import ToroRight from "../../../assets/img/SpritesToro/Toro derecha.gif";
import ToroAttackRight from "../../../assets/img/SpritesToro/Ataque derecho toro.gif";
import ToroAttackFront from "../../../assets/img/SpritesToro/Ataque frontal toro.gif";
import { isMobile } from "../../../utils/validateDevice";
import { formatDistanceToNowStrict, isAfter } from "date-fns";

export const WebSiteLegend = ({ modeDebug }) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [menuNFT, setMenuNFT] = useState("legends");
  const [activePlay, setActivePlay] = useState("");
  const [keyPress, setKeyPress] = useState("");
  const [imageSprite, setImageSprite] = useState({
    img: ToroFront,
    reverse: false,
  });

  useEffect(() => {
    /*     let $howToPlay = document.getElementById('howToPlay'); */
    document.addEventListener("keydown", (e) => {
      setKeyPress(e.key);
      switch (e.key) {
        case "ArrowUp":
          setImageSprite({ img: ToroBack, reverse: false });
          break;

        case "a":
          setImageSprite({ img: ToroRight, reverse: true });
          break;

        case "s":
          setImageSprite({ img: ToroFront, reverse: false });
          break;

        case "d":
          setImageSprite({ img: ToroRight, reverse: false });
          break;

        case "w":
          setImageSprite({ img: ToroBack, reverse: false });
          break;

        case "ArrowLeft":
          setImageSprite({ img: ToroAttackRight, reverse: true });
          break;

        case "ArrowDown":
          setImageSprite({ img: ToroAttackFront, reverse: false });
          break;

        case "ArrowRight":
          setImageSprite({ img: ToroAttackRight, reverse: false });
          break;
        default:
          break;
      }
    });
    document.addEventListener("keyup", (e) => {
      setTimeout(() => {
        setKeyPress("");
      }, 500);
    });

    intervalTimePopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const intervalTimePopup = () => {
    const dateEnd = new Date(2022, 10, 26, 19, 55); //26 nov 7:55pm
    const resultAfter = isAfter(new Date(), dateEnd);
    if (resultAfter) return;
    let timeLeft = formatDistanceToNowStrict(dateEnd, { unit: "second" });
    timeLeft = Number(timeLeft.split(" ")[0]);
    AlertDNATwo({
      allowOutsideClick: modeDebug,
      showConfirmButton: modeDebug,
      title: "Hello Legends!",
      html: "Our Laboratory will be waiting to be discovered by you next November 26th. <br> <br> Time Left: <b></b>",
      backdrop: true,
      timeLeft: timeLeft * 1000,
    });
  };

  const handleMenuNFT = (item) => {
    if (item === "accessories" || item === "maps") return;
    setMenuNFT(item);
  };
  const handlePlay = () => {
    if (isMobile()) {
      AlertDNA({
        title: t("website.alerts.titleGameMobile"),
        text: t("website.alerts.textGameMobile"),
      });
    } else {
      setActivePlay("active");
      setTimeout(() => {
        navigate("/playnow");
      }, 3000);
    }
  };

  const handleWhitePaper = () => {
    /*  AlertDNA({ title: t('website.alerts.titleWhitepapper'), text: t('website.alerts.textWhitepapper') }); */
    const win = window.open(
      `${window.location.origin}/assets/Whitepaper.pdf`,
      "_blank"
    );
    win.focus();
  };
  return (
    <StylesHome>
      <NavBar
        handlePlay={handlePlay}
        handleWhitePaperAction={handleWhitePaper}
      ></NavBar>
      <HeroStyles>
        <Container>
          <div className="row">
            <div className="col-6 pos-relative">
              <div className="text-title">
                <div className="first-title">
                  <p>NO PLAY</p>
                </div>
                <div className="second-title">
                  <p>NO GAIN</p>
                </div>
              </div>
              <p className="text-description">
                {t("website.hero.description")}
              </p>
              <div className="section-mutant">
                <img src={Mutante} alt="mutante1" />
              </div>
              <div className="section-info">
                <div className="btn-hero">
                  <Button
                    color="var(--primary-color)"
                    text="WHITEPAPER"
                    bgColorHover="var(--primary-color-medium)"
                    onPress={handleWhitePaper}
                  ></Button>
                </div>
              </div>

              <SocialMedia>
                <div className="item-social">
                  <a
                    href="https://discord.gg/6DxEPE9DvS"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Discord color="#F0F0F0" link />
                  </a>
                </div>
                <div className="item-social">
                  <a
                    href="https://t.me/dnalegends"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Telegram color="#F0F0F0" link />
                  </a>
                </div>

                <div className="item-social">
                  <a
                    href="https://instagram.com/dnaleg3nds?igshid=YmMyMTA2M2Y="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Instagram color="#F0F0F0" link />
                  </a>
                </div>
                <div className="item-social">
                  <a
                    href="https://www.facebook.com/DNA-Legends-104342229048356"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook color="#F0F0F0" link />
                  </a>
                </div>
              </SocialMedia>
            </div>
          </div>
          <div className="arrow-side">
            <img src={ArrowOne} alt="arrow" />
            <div>
              <p className="text-scroll">{t("website.scroll.text")} </p>
            </div>
          </div>
        </Container>
      </HeroStyles>
      <HowToPlay id="howToPlay">
        <div>
          <Container>
            <div className="title-section">
              <p className="title">
                <span>{t("website.howPlay.firstText")} </span>
                {t("website.howPlay.secondText")}
              </p>
              <div className="text-description">
                <p>{t("website.howPlay.description")}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-l-12 keyboard">
                <div className="grid-keyboard">
                  <div className={`key ${keyPress === "w" && "press"}`}>W</div>
                  <div className={`key ${keyPress === "a" && "press"}`}>A</div>
                  <div className={`key ${keyPress === "s" && "press"}`}>S</div>
                  <div className={`key ${keyPress === "d" && "press"}`}>D</div>
                </div>
                <p className="text-item">{t("website.howPlay.move")}</p>
              </div>
              <div className="col-4 col-l-12 mutant">
                <img
                  src={imageSprite.img}
                  alt="Pixel Mutant"
                  className={imageSprite.reverse && "reverse"}
                />
                {/*  <div className="shadow-mutant"></div> */}
              </div>
              <div className="col-4 col-l-12 keyboard">
                <div className="grid-keyboard">
                  <div className={`key ${keyPress === "ArrowUp" && "press"}`}>
                    ↑
                  </div>
                  <div className={`key ${keyPress === "ArrowLeft" && "press"}`}>
                    ←
                  </div>
                  <div className={`key ${keyPress === "ArrowDown" && "press"}`}>
                    ↓
                  </div>
                  <div
                    className={`key ${keyPress === "ArrowRight" && "press"}`}
                  >
                    →
                  </div>
                </div>
                <p className="text-item">{t("website.howPlay.attack")}</p>
              </div>
            </div>
            <div className="action">
              <div>
                <p className="text-keyboard">
                  {t("website.howPlay.keyboard")}{" "}
                </p>
                <div className="btn-play">
                  <Button
                    text={t("playNow")}
                    bgColor="var(--primary-color)"
                    onPress={handlePlay}
                  />
                  {/* <LinkButton text={t('website.howPlay.buy')} fontSize={'20px'} color="#b0b2b7" Icon={Cart} /> */}
                </div>
              </div>
            </div>
            <div className="arrow-side">
              <img src={ArrowTwo} alt="arrow" />
            </div>
          </Container>
        </div>
      </HowToPlay>
      <CardToPlay>
        <Container>
          <div className="title-main">
            <p>
              {t("website.cardPlay.mainNormal")}
              <span>{t("website.cardPlay.mainBold")}</span>
            </p>
          </div>
          <div className="row">
            <div className="col-6 col-l-12">
              <div className="card-main row">
                <div className="text-card">
                  <p className="title">Free to play</p>
                  <p className="description">
                    {t("website.cardPlay.descriptionLeft")}
                  </p>
                  <div className="hashtag">
                    <span>{t("website.cardPlay.hashGame")}</span>
                    <span>{t("website.cardPlay.hashPlay")}</span>
                    <span>{t("website.cardPlay.hashLegends")}</span>
                    <span>{t("website.cardPlay.hashFreeToPlay")}</span>
                  </div>
                  <div className="btn-play">
                    <Button
                      text={t("playNow")}
                      bgColor="var(--primary-color)"
                      onPress={handlePlay}
                    />
                  </div>
                </div>
                <div className="img-card">
                  <img src={Toro} alt="Toro" />
                </div>
              </div>
            </div>
            <div className="col-6 col-l-12">
              <div className="card-main row end-text">
                <div className="img-card left">
                  <img src={Rino} alt="Rino" />
                </div>
                <div className="text-card right">
                  <p className="title ">PLAY TO EARN</p>
                  <p className="description">
                    {t("website.cardPlay.descriptionRight")}
                  </p>
                  <div className="hashtag ">
                    <span>{t("website.cardPlay.hashWin")}</span>
                    <span>#NFT's</span>
                    <span>{t("website.cardPlay.hashLegends")}</span>
                    <span>{t("website.cardPlay.hashInvestment")}</span>
                  </div>
                  <div className="btn-play">
                    <Button
                      text="WHITEPAPER"
                      bgColor="var(--secondary-color)"
                      color="#992655"
                      bgColorHover="#ff579c"
                      onPress={handleWhitePaper}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="links">
            <LinkButton
              text={t('website.cardPlay.benefits')}
              color="#b0b2b7"
              hoverColor="white"
              fontSize="20px"
              Icon={Star}
            />
            <LinkButton text={t('buyNFT')} color="#b0b2b7" hoverColor="white" fontSize="20px" Icon={Cart} />
          </div> */}
        </Container>
      </CardToPlay>
      <GameForAll id="howToWin">
        <div className="title-main">
          <div className="title">
            <p>{t("website.gameAll.titleUp")}</p>
            <p>{t("website.gameAll.titleDown")}</p>
            <img src={Arrows} alt="arrows" className="left-arrow" />
            <img src={Arrows} alt="arrows" className="right-title" />
          </div>
        </div>
        <Container>
          <CarrouselNFT handlePlay={handlePlay} />
          <div className="arrow-side">
            <img src={ArrowTwo} alt="arrow" />
          </div>
        </Container>

        <div className="title-main">
          <div className="title">
            <img className="right" src={Arrows} alt="arrows" />
          </div>
        </div>
      </GameForAll>
      <NFT id="nfts">
        <Container>
          <div className="main-section">
            <div>
              <p className="title-main">nft’s</p>
            </div>
            <div className="tabs">
              {[
                { text: "legends", value: "legends" },
                { text: t("website.nft.maps"), value: "maps" },
                { text: t("website.nft.accesories"), value: "accessories" },
                { text: t("website.nft.rarity"), value: "rarity" },
              ].map((el) => {
                return (
                  <div
                    className={`item-tab ${
                      menuNFT === el.value ? "active" : ""
                    }`}
                    onClick={() => handleMenuNFT(el.value)}
                    key={el.value}
                  >
                    {el.text}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="info-section">
            <div className="text-info">
              <img src={infoIcon} alt="icon" />
              <p> {t("website.nft.info")}</p>
            </div>
            <div>
              {/*  <LinkButton
                text={t('website.nft.openMarketplace')}
                color="#b0b2b7"
                hoverColor="white"
                fontSize="var(--fs-normal)"
                Icon={Cart}
              /> */}
            </div>
          </div>
          {menuNFT === "legends" && (
            <div className="carrousel">
              <Carrousel />
            </div>
          )}
          {menuNFT === "maps" && (
            <div className="carrousel">
              <h2>maps</h2>
            </div>
          )}
          {menuNFT === "accessories" && (
            <div className="carrousel">
              <h2>accessories</h2>
            </div>
          )}
          {menuNFT === "rarity" && (
            <div className="rarity-section">
              <p className="title">{t("website.nft.titleRarity")}</p>
              <div className="row degrade font-size">
                <p>A</p>
                <p>B</p>
                <p>C</p>
                <p>D</p>
                <p>E</p>
                <p>F</p>
              </div>
              <div className="rare-text">
                <div>
                  <p className="title-rare">+ {t("website.nft.rare")}</p>
                </div>
                <div>
                  <p className="title-rare">{t("website.nft.lessRare")} -</p>
                </div>
              </div>
              <div className="row degrade indicator">
                <p>0.275%</p>
                <p>0.824%</p>
                <p>2.473%</p>
                <p>7.418%</p>
                <p>22.253%</p>
                <p>66.757%</p>
              </div>
              <p className="title">{t("website.nft.created")}</p>
              <div className="btn-info">
                <p cl>{t("website.nft.moreInfo")}</p>
                <Button
                  text="WHITEPAPER"
                  color="var(--primary-color)"
                  bgColorHover="var(--primary-color-medium)"
                  onPress={handleWhitePaper}
                />
              </div>
            </div>
          )}
        </Container>
      </NFT>
      <DashboardToken>
        <Container>
          <div className="main-section">
            <div>
              <p className="title-main">{t("website.dashboardToken.title")}</p>
            </div>
            <div>
              <p className="text-description">
                {" "}
                {t("website.dashboardToken.description")}
              </p>
            </div>
          </div>
          <div></div>
          <div className="graphic row">
            {/*  <Doughnut /> */}
            <div className="col-8 col-l-12">
              <img src={Graphic} alt="graphic" />
            </div>

            <div className="btn-info col-3 col-l-12">
              <p style={{ textAlign: "center" }}>
                {t("website.dashboardToken.moreInfo")}{" "}
              </p>
              <Button
                text="WHITEPAPER"
                color="var(--primary-color)"
                bgColorHover="var(--primary-color-medium)"
                onPress={handleWhitePaper}
              />
            </div>
          </div>
          <div className="graphic-table">
            <div className="col-10">
              <ItemGraphic color="#00CEC7">
                <span className="square"></span>
                {t("team")}(20%)
              </ItemGraphic>
              <ItemGraphic color="var(--primary-color)">
                <span className="square"></span>
                {t("website.dashboardToken.staking")} (20%)
              </ItemGraphic>
              <ItemGraphic color="var(--secondary-color)">
                <span className="square"></span>
                Private Sale (10%)
              </ItemGraphic>
              <ItemGraphic color="#00817D">
                <span className="square"></span>
                Marketing (20%)
              </ItemGraphic>
              <ItemGraphic color="#B1C5D1">
                <span className="square"></span>
                Presale (8%)
              </ItemGraphic>
              <ItemGraphic color="#00CEC7">
                <span className="square"></span>
                External Cost (5%)
              </ItemGraphic>
              <ItemGraphic color="#00817D">
                <span className="square"></span>
                DNA Fundation (5%)
              </ItemGraphic>
              <ItemGraphic color="#B1C5D1">
                <span className="square"></span>
                Liquidity Provision Fund (5%)
              </ItemGraphic>
              <ItemGraphic color="#00817D">
                <span className="square"></span>
                Launchpool (4%)
              </ItemGraphic>
              <ItemGraphic color="#00CEC7">
                <span className="square"></span>
                Referral Program (3%)
              </ItemGraphic>
            </div>
            <div className="col-2 text-align">
              <div className="line-total"></div>
              <p>100%</p>
            </div>
          </div>
        </Container>
      </DashboardToken>
      <Partners>
        <Container>
          <div className="main-section">
            <div>
              <p className="title-main">{t("website.partners.title")}</p>
            </div>
          </div>
        </Container>
        <div className="logo-partners">
          {/* <div>
            <img src={Sexus} alt="sexus" />
          </div> */}
          <div>
            <img src={TokenPartner} alt="Token Parner" />
          </div>
          <div>
            <img src={DNAcademy} alt="DNA Academy" />
          </div>
          <div>
            <img src={Livan} alt="livan" />
          </div>

          <div>
            <img src={EF} alt="EF" />
          </div>
          {/* <div>
            <img src={Chainlink} alt="ChainLink" />
          </div>
          */}
        </div>
      </Partners>
      <FooterLegends handleWhitePaperAction={handleWhitePaper} />
      <PlayNowStyles>
        <div className={activePlay}></div>
      </PlayNowStyles>
    </StylesHome>
  );
};
