import React from 'react';
import { StylesLogo } from './styles';

import LogoDNALegends from '../../assets/img/LogoDNAWhite.png';
import TextDNA from '../../assets/img/TextLegendsDNA.svg';
export const LogoDNAWhite = ({ width, widthImg, opacity }) => {
  return (
    <StylesLogo width={width} widthImg={widthImg} transitionAlternative move="fast" opacity={opacity}>
      <div className="image-section">
        <div className="section-logo">
          <div className="logo-dna">
            <img src={LogoDNALegends} alt="logo" />
          </div>
          <div className="text-dna">
            <img src={TextDNA} alt="textDNA" />
          </div>
        </div>
      </div>
    </StylesLogo>
  );
};
