import styled from 'styled-components';
import CardNft from '../../assets/img/Cards/Card-NFT.png';
import { device } from '../../constants/device';

export const GeneralCarrousel = styled.section`
  .swiper-button-next,
  .swiper-button-prev {
    color: var(--primary-color);
    &::after {
      content: '>';
      font-family: 'Press Start 2P';
    }
  }
  .swiper-button-prev {
    &::after {
      content: '<';
    }
  }
  .swiper-pagination-bullet {
    border-radius: 0;
    width: 10px;
    height: 10px;
    background-color: #6f98c8;
  }
  .swiper-pagination-bullet-active {
    background-color: var(--primary-color);
    width: 15px;
    height: 15px;
  }
  .swiper-pagination {
    text-align: right;
  }
`;
export const StylesCardCarrousel = styled.section`
  /* box-shadow: 0 0 8px 1px #00000063; */
  backdrop-filter: blur(7.5px);
  text-align: center;
  position: relative;
  transform: scale(0.9);
  transition: transform 0.5s linear;
  margin: 1rem 0 3rem;
  background: rgba(31, 40, 51, 0.6);
  opacity: 0.7;
  filter: ${(props) => (props.blurEffect ? 'blur(7px)' : 'blur(0px)')};
  & > div {
    height: 100%;
    width: 100%;
  }
  & img {
    height: 456px;
    &.img-hidden {
      width: 50%;
    }
  }
  & .name {
    font-size: var(--fs-normal);
    font-weight: bold;
    padding-bottom: 30px;
  }
  & .price {
    font-family: 'Press Start 2P';
    font-size: var(--fs-carrousel);
    padding-bottom: 20px;
    color: var(--primary-color);
  }
  & .pos-absolute {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
  }
  & .letter {
    position: absolute;
    top: 20px;
    right: 20px;
    font-family: 'Press Start 2P';
    font-size: var(--fs-medium);

    color: var(--secondary-color);
  }
  & button {
    display: none;
  }
  &:not(.active):hover {
    opacity: 1;
    cursor: pointer;
    transform: scale(1);
    background: url('${CardNft}') no-repeat;
    background-size: 100% 100%;
    filter: blur(0px);
    & button {
      display: block;
      margin: 0 auto 2rem;
    }
    & .name {
      font-size: var(--fs-medium);
    }
  }
  &.active {
    opacity: 1;
    transform: scale(1);
    & .name {
      font-size: var(--fs-medium-two);
      font-weight: bold;
      padding-bottom: 40px;
    }
    & .price {
      font-family: 'Press Start 2P';
      font-size: var(--fs-medium-two);
      padding-bottom: 40px;
      color: var(--primary-color);
    }
    & .pos-absolute {
      position: absolute;
      bottom: 25px;
      left: 0;
      right: 0;
    }
    & .letter {
      position: absolute;
      top: 30px;
      right: 20px;
      font-family: 'Press Start 2P';
      font-size: var(--fs-medium-large-two);

      color: var(--secondary-color);
    }
    & button {
      display: block;
      margin: auto;
    }
    & img {
      padding-bottom: 80px;
    }
  }

  @media ${device.desktop} {
    & img {
      height: 400px;
    }
  }
  @media ${device.tabletL} {
    &:not(.active):hover {
      & button {
        display: none;
      }
    }
  }
  @media ${device.tablet} {
    & img {
      height: 440px;
    }
  }
`;
