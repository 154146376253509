import { StyleButton } from './styles';

export const Button = ({ text, bgColor, color, bgColorHover, type = 'button', onPress, disabled, fontSize, widthFull=false }) => (
  <StyleButton
    disabled={disabled}
    type={type}
    bgColor={bgColor}
    color={color}
    onClick={onPress}
    fontSize={fontSize}
    bgColorHover={bgColorHover}
    widthFull={widthFull}
  >
    <span>{text}</span>
  </StyleButton>
);
