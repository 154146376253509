import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';
import USA from '../../assets/img/Flag/usa.svg';
import ESP from '../../assets/img/Flag/Spanish.png';
import { SectionSelect } from './styles';
import { useMainContext } from '../../context/MainContext';
const friendOptions = [
  {
    key: 'en',
    text: 'ENG',
    value: 'en',
    image: { avatar: true, src: USA },
  },
  {
    key: 'es',
    text: 'ESP',
    value: 'es',
    image: { avatar: true, src: ESP },
  },
];
export const LanguageSelect = (e) => {
  const { i18n } = useTranslation();
  const { dataMain, setDataMain } = useMainContext();
  let languageSelected = useRef('en');
  useEffect(() => {
    let lang = dataMain.languagePage?.split('-')[0] || 'en';
    languageSelected.current = lang;
  }, [dataMain]);

  const changeLanguage = (e, { name, value }) => {
    i18n.changeLanguage(value);
    languageSelected.current = value;
    setDataMain({ ...dataMain, languagePage: value });
  };

  return (
    <SectionSelect>
      <Dropdown
        placeholder="Select Language"
        fluid
        selection
        options={friendOptions}
        onChange={changeLanguage}
        value={languageSelected.current}
      />
    </SectionSelect>
  );
};
