import React from 'react';
import { StylesLink } from './styles';

export const LinkButton = ({
  text,
  onPress,
  disabled,
  fontSize,
  color = 'var(--primary-color)',
  underline = true,
  hoverColor = 'var(--secondary-color)',
  hoverCursiveFont = false,
  Icon = null,
}) => {
  return (
    <StylesLink
      disabled={disabled}
      onClick={onPress}
      fontSize={fontSize}
      color={color}
      underline={underline}
      hoverColor={hoverColor}
      cursive={hoverCursiveFont}
    >
      {Icon && <img src={Icon} alt="icono" />}
      <span>{text}</span>
    </StylesLink>
  );
};
